import axios from 'axios';
import Vue from 'vue';
import router from '@/router';
import store from '@/store';

export const instance = () => {
  const createdInstance = axios.create({
    baseURL: process.env.VUE_APP_URL_API_CRM,
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
      'X-Access-Token': localStorage.getItem("token"),
    },
  });

  createdInstance.interceptors.response.use(
    response => response.data,
    error => {
      if (error.response && error.response.status === 401) {
        store.dispatch('app/logout');
        router.push('/login').catch(() => {});
      }

      return Promise.reject(error);
    },
  );

  return createdInstance;
};

export default instance;
