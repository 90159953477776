<template>
  <div class="chat-message-container">
    <avatar
      :hasStatus="false"
      :hasBorder="false"
      :avatarLetter="$service.getFirstLetter(message.name)"
      :random-color="message.name"
      avatarColor="#FFFFFF"
    />

    <div class="chat-message-content">
      <div class="d-flex">
        <h5>
          {{ message.name }}
        </h5>

        <p class="hour">
          {{ moment(message.timestamp).format("DD/MM/YY HH:mm:ss") }}
        </p>
      </div>

      <p class="message">
        {{ message.message }}
      </p>

      <div v-for="(file, index) in message.files" :key="index">
        <div
          v-if="
            (getMimeType(file.mime) == 'image' ||
              getMimeType(file.mime) == 'video') &&
            hasVideoImageLoading
          "
          class="image-video-loading"
        >
          <b-spinner :variant="'primary'" />
        </div>

        <audio
          v-if="getMimeType(file.mime) == 'audio' && !isIOS()"
          preload="auto"
          controls
        >
          <source :src="file.url" :type="file.mime" />
        </audio>

        <div
          class="audio-link"
          v-if="getMimeType(file.mime) == 'audio' && isIOS()"
        >
          <a :href="file.url">Clique para escutar o áudio.</a>
        </div>

        <img
          v-if="getMimeType(file.mime) == 'image'"
          v-show="!hasVideoImageLoading"
          @click="openCarouselModal(file)"
          @load="imageVideoLoaded()"
          :src="file.url"
          :alt="'image' + file.name"
          class="image-file"
        />

        <div v-if="getMimeType(file.mime) == 'sticker'" class="sticker-file">
          Sticker ainda não suportado!
        </div>

        <video
          v-if="getMimeType(file.mime) == 'video'"
          v-show="!hasVideoImageLoading"
          @loadedmetadata="imageVideoLoaded()"
          class="video-file"
          controls
        >
          <source :src="file.url" />
          Seu navegador não suporta a reprodução de vídeos.
        </video>

        <a
          v-if="getMimeType(file.mime) == 'document'"
          :href="file.url"
          class="document-file"
          target="_blank"
        >
          <div class="file-name">{{ file.name }}</div>

          <i class="icon-arrow-down" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/views/components/Avatar.vue";
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    Avatar,
    BSpinner,
  },

  name: "ChatMessage",

  props: ["message"],

  data() {
    return {
      hasVideoImageLoading: true,
    };
  },

  mounted() {
    if (this.message.files) {
      this.message.files.forEach((file) => {
        const audio = this.$refs["audio" + this.message.timestamp + file.name];
        this.$nextTick(() => {
          if (audio && audio[0]) {
            audio[0].load();
          }
        });
      });
    }
  },

  methods: {
    openImageBlank(link) {
      window.open(link, "_blank");
    },

    imageVideoLoaded() {
      this.hasVideoImageLoading = false;
    },

    openCarouselModal(file) {
      this.$store.dispatch("modalCarousel/setSpecificImage", file.url);

      this.$store.dispatch("modalCarousel/changeShowCarousel", true);
    },

    isIOS() {
      const userAgent = navigator.userAgent;

      return /iPhone|iPad|iPod/i.test(userAgent);
    },

    getMimeType(type) {
      const possibleTypes = {
        "audio/aac": "audio",
        "audio/mp4": "audio",
        "audio/amr": "audio",
        "audio/mpeg": "audio",
        "audio/ogg": "audio",
        "audio/mp3": "audio",
        "audio/wav": "audio",
        "audio/opus": "audio",

        "image/jpeg": "image",
        "image/png": "image",

        "image/webp": "sticker",

        "video/mp4": "video",
        "video/3gpp": "video",

        "text/plain": "document",
        "application/pdf": "document",
        "application/vnd.ms-powerpoint": "document",
        "application/msword": "document",
        "application/vnd.ms-excel": "document",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          "document",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation":
          "document",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          "document",
      };

      let documentType = possibleTypes[type];

      return documentType;
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-message-container {
  display: flex;
  width: 100%;
  padding: 1rem;

  i {
    font-size: 1.5rem;
    color: rgba(161, 161, 170, 0.5);
  }

  .chat-message-content {
    width: 100%;
    margin-left: 1rem;

    h5 {
      color: #000;
      margin-bottom: 0;
    }

    p {
      text-align: left;
      margin-bottom: 0;

      &.hour {
        margin-left: 0.5rem;
        color: #999;
        font-size: 10px;
      }

      &.message {
        color: #4c5862;
        font-size: 13px;
      }
    }
  }

  .image-video-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2rem 50px 2rem 0;
    margin-top: 10px;
  }

  .audio-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    border-radius: 30px;
    background: #f1f2f5;
    padding: 6px 12px;

    a {
      color: blue;
      text-decoration: none;
    }
  }

  .image-file {
    height: auto;
    width: 100%;
    margin-top: 10px;
    border-radius: 20px;
    border: 5px solid #2196f333;
    cursor: pointer;
  }

  .video-file {
    width: 100% !important;
    height: auto !important;
    margin-top: 10px;
    border-radius: 20px;
    border: 5px solid #2196f333;
  }

  .document-file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 350px;
    padding: 5px 10px;
    margin-top: 10px;
    border-radius: 16px 16px 16px 0px;
    border: 10px solid #f0eff5;
    color: #0f1828;
    cursor: pointer;

    .file-name {
      line-height: 16px;
      letter-spacing: 0em;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 100%;
      padding: 1px 0;
      max-width: 80%;
      overflow: hidden;
    }

    i {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background: #f0eff5;
      color: #0f1828;
    }
  }
}
</style>
