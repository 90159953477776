/* eslint-disable no-empty-pattern */
import Vue from "vue";
import { instance } from "@/config/index.js";

const initialState = () => ({});

const getters = {};

const mutations = {};

const actions = {
  list({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/contacts${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  fetchOne({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/contacts/${params}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  fetchLogs({}, data) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(
          `/workspaces/${workspaceId}/contacts/${data.contactId}/logs${
            data.params || ""
          }`
        )
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  saveContact({}, contact) {
    let workspaceId = localStorage.getItem("currentWorkspace");

    if (contact.workspace_id) {
      workspaceId = contact.workspace_id;
    }
    const postUrl = `/workspaces/${workspaceId}/contacts`;

    return new Promise((resolve, reject) => {
      instance()
        .put(`${postUrl}/${contact.id}`, contact)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};
