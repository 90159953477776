<template>
  <div class="floating-messages">
    <div
      class="d-flex w-100"
      style="justify-content: flex-end; padding-right: 1rem"
    >
      <i @click="closeFloatingMessages" class="icon-close-circle-outline" />
    </div>

    <div class="gray-detail" />

    <div class="box-title">Respostas Rápidas</div>

    <div v-if="filteredQuickMessages.length" class="quick-messages-box">
      <div class="quick-message">
        <div class="command">/Atalho</div>

        <div class="message">Mensagem</div>
      </div>

      <div
        v-for="(quickMessage, index) in filteredQuickMessages"
        @click="selectQuickMessage(quickMessage, lastWord)"
        :key="index"
        class="quick-message"
      >
        <div class="command">{{ quickMessage.id }}</div>

        <div class="message">{{ quickMessage.message }}</div>
      </div>
    </div>

    <div v-else class="no-messages-found">
      Nenhuma mensagem rápida encontrada.
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "",
    },
  },

  computed: {
    quickMessages() {
      return this.$store.getters["omnichannel/getQuickMessages"];
    },

    lastWord() {
      let lastWord;

      if (this.message) {
        lastWord = this.message.split(" ");

        lastWord = lastWord[lastWord.length - 1];
      }

      return lastWord;
    },

    filteredQuickMessages() {
      const fitleredMessages = [];

      this.quickMessages.forEach((element) => {
        if (element.id.includes(this.lastWord)) {
          fitleredMessages.push(element);
        }
      });

      return fitleredMessages;
    },
  },

  methods: {
    emit(event, params) {
      this.$emit(event, params);
    },

    closeFloatingMessages() {
      this.emit("close");
    },

    selectQuickMessage(quickMessage, lastWord) {
      const messageInfo = {
        message: quickMessage.message,
        lastWord: lastWord,
      };

      this.emit("quickMessageSelected", messageInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.floating-messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1000;
  bottom: calc(100% + 1px);
  padding-top: 1rem;
  border-radius: 20px 20px 0px 0px;
  border: 1px solid #e5e5e5;
  box-shadow: -5px -5px 16px 0px #0000001a;
  background: #ffffff;

  .icon-close-circle-outline {
    font-size: 18px;
    color: #14223b;
    cursor: pointer;
  }

  .icon-close-circle-outline:hover {
    color: #ff0000 !important;
  }

  .gray-detail {
    height: 5px;
    width: 100%;
    max-width: 135px;
    margin-bottom: 20px;
    border-radius: 20px;
    opacity: 0.5;
    background: #c4ccd8;
  }

  .box-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    margin-bottom: 15px;
    color: #000000;
  }

  .quick-messages-box {
    display: flex;
    flex-direction: column;
    max-height: 350px;
    width: 100%;
    overflow-y: scroll;

    .quick-message {
      display: flex;
      align-items: center;
      padding: 0.75rem;
      border-bottom: solid 1px #e5e5e5;
      cursor: pointer;

      .command,
      .message {
        font-size: 13px;
        letter-spacing: 0.01em;
        text-align: left;
      }

      .command {
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 21.45px;
        width: calc(18% + 10px);
        min-width: calc(18% + 10px);
        padding-right: 10px;
        color: #a39f9f;
        overflow: hidden;
      }

      .message {
        line-height: 19.5px;
        color: #4c5862;
      }
    }

    .quick-message:first-child {
      .command {
        color: #000000 !important;
      }

      .message {
        color: #000000 !important;
      }
    }

    .quick-message:last-child {
      border-bottom: none;
    }

    .quick-message:first-child:hover {
      background: #ffffff;
    }

    .quick-message:hover {
      background: #e5e5e5;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 13px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 13px;
      border: 1px solid transparent;
      background-clip: content-box;
      box-shadow: inset 0 0 0 10px #c8c8c8;
    }
  }

  .no-messages-found {
    text-align: left;
    width: 100%;
    padding: 0 1rem 1.2rem 1rem;
  }
}

@media (max-width: 800px) {
  .floating-messages {
    width: 100vw;
  }
}
</style>
