<template>
  <b-container
    @dragover.prevent
    @dragenter.prevent="handleDragEnter($event)"
    id="opportunity-and-contacts"
    class="general-content"
  >
    <close-opportunity-from-chat
      v-if="showCloseChat"
      @closeHalfModal="closeHalfModal"
      @refresh="emitEvents('saved')"
      :opportunity="opportunityForChat"
      :fromOmnichannelDesktop="fromOmnichannelDesktop"
    />

    <b-tabs
      @input="changeTab($event)"
      :id="
        fromOmnichannelDesktop ? 'icons-menu-omnichannel-desktop' : 'icons-menu'
      "
    >
      <b-tab v-if="!fromOmnichannelDesktop" id="icon" @click="verifyCancel">
        <template slot="title">
          <i style="cursor: pointer" class="icon-close" id="icon-red" />
        </template>
      </b-tab>

      <b-tab
        :active="fromOmnichannelDesktop || fromOmnichannelMobile"
        :style="
          !fromOmnichannelDesktop ? 'padding-top: 115px' : 'padding-top: 24px'
        "
        id="icon"
        class="modal-space"
      >
        <template slot="title">
          <i class="icon-imbot" style="font-size: 25px" id="icon-green" />
        </template>

        <card-top-info-component
          v-if="!fromOmnichannelDesktop"
          @whats="taskFromWhats"
          @call="taskFromCall"
          @email="taskFromEmail"
          @closeByChat="closeByChat"
          :opportunity="opportunity"
          :fromChat="opportunity.chat_history ? true : false"
          :fromOmnichannelDesktop="fromOmnichannelDesktop"
          class="absolute-top-info"
        />

        <chat-rocket :opportunity="opportunityForChat" />

        <drop-file-Box v-show="hasDraggable" />

        <input-chat-bar
          @roomReopened="handleRoomOpened()"
          :opportunity="opportunityForChat"
        />
      </b-tab>

      <b-tab
        v-if="!fromOmnichannelDesktop"
        :active="!fromOmnichannelMobile"
        id="icon"
        class="modal-space"
      >
        <template slot="title">
          <i class="icon-user-profile" id="icon-blue" />
        </template>

        <opportunity-form
          :id="opportunity.id"
          :isOnlyHistoric="isOnlyHistoric"
          :isUpdate="true"
          :watchVerifyCancel="finishVerify"
          :watchSave="watchSave"
          :isFromOpportunityAndContacts="!isOnlyHistoric"
          :isFromExternalCall="isFromExternalCall"
          @saved="emitEvents('saved')"
          @closed="emitEvents('closed')"
          @refresh="emitEvents('refresh')"
          @opportunityTransfered="emitEvents('opportunityTransfered')"
        />
      </b-tab>

      <b-tab id="icon" class="modal-space">
        <template slot="title">
          <i class="icon-phone-chat-icon" id="icon-blue" />
        </template>

        <chat-phone :opportunity="opportunity" @call="taskFromCall" />
      </b-tab>

      <b-tab id="icon" class="modal-space">
        <template slot="title">
          <i class="icon-task-clock" id="icon-blue" />
        </template>

        <contact-history
          v-if="opportunity.contact"
          @refresh="emitEvents('saved')"
          :contact="opportunity.contact"
          id="contact-history"
        />
      </b-tab>

      <b-tab id="icon" class="modal-space">
        <template slot="title">
          <i class="icon-file" id="icon-blue" />
        </template>

        <card-top-info-component
          v-if="!fromOmnichannelDesktop"
          @whats="taskFromWhats"
          @call="taskFromCall"
          @email="taskFromEmail"
          @closeByChat="closeByChat"
          :opportunity="opportunity"
          :fromOmnichannelDesktop="fromOmnichannelDesktop"
        />

        <opportunity-description
          @saved="emitEvents('saved')"
          :opportunity="opportunity"
          :fromOmnichannelDesktop="fromOmnichannelDesktop"
        />
      </b-tab>
    </b-tabs>

    <b-modal
      class="modal-dialog"
      id="bv-task_form"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="d-block text-center" v-if="opportunity">
        <task-form
          createdFrom="Opportunity"
          :opportunity="opportunity"
          :parentId="opportunity.id"
          :fromTaskWhatsapp="fromWhatsapp"
          :fromTaskCall="fromCall"
          :fromTaskEmail="fromEmail"
          @closed="hideModal('bv-task_form')"
          @saved="savedTaskForm()"
        />
      </div>
    </b-modal>

    <b-modal id="bv-verify-cancel-opportunity" hide-footer hide-header>
      <div class="verify-cancel-header">
        <i
          class="icon-close"
          style="cursor: pointer"
          @click="emitEvents('closed')"
        />
      </div>

      <div class="verify-cancel">
        <h2>Salvar atualizações</h2>

        <h4>Você não salvou suas alterações.</h4>

        <h4>Escolha alguma opção!</h4>

        <div class="verify-buttons" v-if="!watchSave">
          <button class="verify-button-close" @click="emitEvents('closed')">
            SAIR SEM SALVAR
          </button>

          <button class="verify-button-save" @click="sendSave">
            <span>SALVAR</span>
          </button>
        </div>

        <div
          v-if="watchSave"
          class="d-flex align-items-center justify-content-center"
        >
          <lottie-animation
            class="lottie-animation-quick-charts"
            ref="anim"
            :animationData="require('@/assets/images/lottie/verify-save.json')"
            :loop="true"
            :autoPlay="true"
            :speed="1"
          />
        </div>

        <img
          src="@/assets/images/write-notebook-illustration.svg"
          class="w-100 mt-4 mx-auto"
        />
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import { BContainer, BTabs, BTab } from "bootstrap-vue";
import CardTopInfoComponent from "../components/Omnichannel/CardTopInfoComponent.vue";
import ChatPhone from "@/views/components/chat/ChatPhone.vue";
import ChatRocket from "@/views/components/chat/ChatRocket.vue";
import CloseOpportunityFromChat from "../components/CloseOpportunityFromChat.vue";
import ContactHistory from "../ContactHistory.vue";
import DropFileBox from "../components/DropFileBox.vue";
import InputChatBar from "../components/Omnichannel/InputChatBar.vue";
import LottieAnimation from "lottie-web-vue";
import OpportunityForm from "./OpportunityForm.vue";
import OpportunityDescription from "./components/OpportunityDescription.vue";
import TaskForm from "@/views/forms/TaskForm.vue";

export default {
  components: {
    BContainer,
    BTab,
    BTabs,
    CardTopInfoComponent,
    ChatPhone,
    ChatRocket,
    CloseOpportunityFromChat,
    ContactHistory,
    DropFileBox,
    InputChatBar,
    LottieAnimation,
    OpportunityForm,
    OpportunityDescription,
    TaskForm,
  },

  props: {
    opportunity: {
      type: Object,
      required: true,
      default: null,
    },

    fromOmnichannelDesktop: {
      type: Boolean,
      default: false,
    },

    fromOmnichannelMobile: {
      type: Boolean,
      default: false,
    },

    isFromExternalCall: {
      type: Boolean,
      default: false,
    },

    isOnlyHistoric: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeTab: null,
      finishVerify: false,
      fromCall: false,
      fromEmail: false,
      fromWhatsapp: false,
      loopCall: null,
      loopState: false,
      mainDiv: null,
      opportunityForChat: this.opportunity,
      showCloseChat: false,
      watchSave: false,
    };
  },

  computed: {
    workspace() {
      return this.$store.getters["app/getUserWorkspace"];
    },

    hasDraggable() {
      return this.$store.getters["omnichannel/getIsHovering"];
    },
  },

  mounted() {
    this.$store.dispatch("omnichannel/resetState");
  },

  beforeDestroy() {
    clearInterval(this.loopCall);
  },

  methods: {
    emitEvents(event) {
      this.$emit(`${event}`);
    },

    scrollToBottom() {
      this.mainDiv = document.getElementById("opportunity-and-contacts");

      if (this.mainDiv && this.opportunityForChat.chat_history) {
        setTimeout(() => {
          this.mainDiv.scrollTo(0, this.mainDiv.scrollHeight);
        }, 250);
      }
    },

    scrollToTop() {
      this.mainDiv = document.getElementById("opportunity-and-contacts");

      if (this.mainDiv) {
        if (window.innerWidth > 800) {
          this.mainDiv.scrollTop = 0;
        } else {
          setTimeout(() => {
            this.mainDiv.scrollTop = 0;
          }, 500);
        }
      }
    },

    closeByChat() {
      this.showCloseChat = true;
    },

    taskFromWhats() {
      this.cleanValues();
      this.fromWhatsapp = true;
      this.openModal("bv-task_form");
    },

    taskFromCall() {
      this.cleanValues();
      this.fromCall = true;
      this.openModal("bv-task_form");
    },

    taskFromEmail() {
      this.cleanValues();
      this.fromEmail = true;
      this.openModal("bv-task_form");
    },

    openModal(modal) {
      this.$bvModal.show(modal);
    },

    closeHalfModal() {
      this.showCloseChat = false;
    },

    hideModal(modal) {
      if (modal) {
        this.$bvModal.hide(modal);
        return;
      }

      this.$bvModal.hide("bv-task_for");
    },

    savedTaskForm() {
      this.emitEvents("closed");
      this.hideModal("bv-task_form");
    },

    cleanValues() {
      this.fromWhatsapp = false;
      this.fromCall = false;
      this.fromEmail = false;
    },

    verifyCancel() {
      if (this.fromOmnichannelMobile || this.isFromExternalCall) {
        this.emitEvents("closed");
      } else {
        this.finishVerify = true;
      }
    },

    handleDragEnter(event) {
      if (this.fromOmnichannelDesktop) {
        if (this.activeTab == 0) {
          this.$store.dispatch("omnichannel/changeHoveringState", true);
        }
      } else {
        if (this.activeTab == 1) {
          this.$store.dispatch("omnichannel/changeHoveringState", true);
        }
      }
    },

    sendSave() {
      this.watchSave = true;
    },

    changeTab(event) {
      if (this.activeTab == event) {
        return;
      }

      this.activeTab = event;

      if (this.fromOmnichannelDesktop) {
        if (this.activeTab != 0) {
          this.stopLoopRequest();
          this.scrollToTop();
        } else {
          this.startLoopRequest();
          this.scrollToBottom();
        }
      } else {
        if (this.activeTab != 1) {
          this.stopLoopRequest();
          this.scrollToTop();
        } else {
          this.startLoopRequest();
          this.scrollToBottom();
        }
      }
    },

    startLoopRequest() {
      this.loopCall = setInterval(this.fetchOpportunity, 5000);
      this.loopState = true;
    },

    stopLoopRequest() {
      this.loopState = false;
      clearInterval(this.loopCall);
    },

    handleRoomOpened() {
      this.fetchOpportunity();

      this.emitEvents("roomReopened");
    },

    fetchOpportunity() {
      const shouldLoop = this.loopState;

      if (shouldLoop) {
        const historyLength = this.opportunityForChat.chat_history
          ? this.opportunityForChat.chat_history.length
          : null;

        this.$store
          .dispatch("opportunityStore/fetchOne", this.opportunityForChat.id)
          .then((res) => {
            const newHistoryLength = res.data.chat_history
              ? res.data.chat_history.length
              : null;

            this.opportunityForChat = res.data;

            if (historyLength < newHistoryLength) {
              this.scrollToBottom();
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.general-content {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: none;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 13px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 13px;
    border: 1px solid transparent;
    background-clip: content-box;
    box-shadow: inset 0 0 0 10px #c8c8c8;
  }

  :deep(#icons-menu-omnichannel-desktop__BV_tab_controls_) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    height: 55px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1005;
    border-bottom: 1px solid #e5e5e5;
    border-radius: 0;
    background: #fff;

    #icon___BV_tab_button__ {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background: #f5f6f8;
      cursor: pointer;

      &.active {
        #icon-red {
          color: #ff2c55;
        }
        #icon-green {
          color: #2ed47a;
        }
        #icon-blue {
          color: #046af3;
        }
      }
      &:after {
        background: transparent !important;
      }
      i {
        color: black;
        margin-right: 0 !important;
        font-size: 18px;
      }
    }

    .nav-item:nth-child(1) a.active {
      border: 1px solid #2ed47a;
    }
    .nav-item:nth-child(2) a.active {
      border: 1px solid #046af3;
    }
    .nav-item:nth-child(3) a.active {
      border: 1px solid #046af3;
    }
    .nav-item:nth-child(4) a.active {
      border: 1px solid #046af3;
    }
  }

  :deep(#icons-menu__BV_tab_controls_) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    height: 55px;
    width: 100vw;
    max-width: 500px;
    top: 0;
    left: 0;
    z-index: 1005;
    border-bottom: 1px solid #e5e5e5;
    border-radius: 0;
    background: #fff;

    #icon___BV_tab_button__ {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background: #f5f6f8;
      cursor: pointer;
      &.active {
        #icon-red {
          color: #ff2c55;
        }
        #icon-green {
          color: #2ed47a;
        }
        #icon-blue {
          color: #046af3;
        }
      }
      &:after {
        background: transparent !important;
      }
      i {
        color: black;
        margin-right: 0 !important;
        font-size: 18px;
      }
    }

    .nav-item:nth-child(1) a.active {
      border: 1px solid #ff2c55;
    }
    .nav-item:nth-child(2) a.active {
      border: 1px solid #2ed47a;
    }
    .nav-item:nth-child(3) a.active {
      border: 1px solid #046af3;
    }
    .nav-item:nth-child(4) a.active {
      border: 1px solid #046af3;
    }
    .nav-item:nth-child(5) a.active {
      border: 1px solid #046af3;
    }
    .nav-item:nth-child(6) a.active {
      border: 1px solid #046af3;
    }
  }
}

.absolute-top-info {
  position: absolute;
  width: 100%;
  top: 55px;
  left: 0;
  padding-left: 24px;
  padding-right: 24px;
  z-index: 1005;

  @media (max-width: 800px) {
    position: fixed;
    border-bottom: 1px solid #e5e5e5;
  }
}

.verify-cancel-header {
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 50px;

  i {
    font-size: 16px;
    color: black;
  }
}

.verify-cancel {
  max-width: 500px;
  height: 100vh;
  padding: 25px;
  text-align: center;
  overflow-y: hidden;

  h2 {
    color: black;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .verify-buttons {
    margin-top: 35px;
    width: 100%;
    height: 50px;
    align-items: center;
    display: flex;
    justify-content: space-around;

    .verify-button-close {
      width: 35vw;
      margin: 0 5px;
      background: transparent;
      border: none;
      color: black;
      font-weight: bold;
    }

    .verify-button-save {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35vw;
      height: 45px;
      margin: 0 5px;
      font-weight: bold;
      border: none;
      background: #046af3;
      border-radius: 10px;
      color: #fff;
    }
  }
}

.modal-space {
  height: 100%;
  margin-top: 55px;
  background: #fff;
}
</style>
