<template>
  <b-card
    @click="openTask()"
    :style="hasTaskId ? 'cursor:pointer' : ''"
    id="taskReopened"
  >
    <div class="header">
      <span class="title">{{ title }} </span>
    </div>

    <div class="d-flex body" style="margin: 4px 0">
      <div style="display: flex; flex-direction: column">
        <span>
          Ação:
          <b style="color: #000">
            {{ getFormatedType(type) }}
          </b>
        </span>

        <span v-if="description">
          Realizado por:
          <b style="color: #000">
            {{ description }}
          </b>
        </span>
      </div>
    </div>

    <div class="footer d-flex flex-column">
      <div
        class="info"
        v-if="user"
        @click="fromUserModal ? openOpportunity(user.id) : ''"
        :class="fromUserModal ? 'cursor-pointer' : ''"
      >
        {{ fromUserModal ? "Realizado na oportunidade:" : "Realizado por:" }}

        <div class="d-flex flex-row">
          <Avatar
            :hasStatus="false"
            :avatarLetter="$service.getFirstLetter(user.name || 'X')"
            avatarColor="#FFFFFF"
            avatarBackground="#109CF1"
          />

          <div class="d-flex flex-column">
            <span class="name"> {{ user.name }} </span>
            <span class="cod" v-if="user.id"> #{{ user.id }} </span>
          </div>
        </div>
      </div>

      <div
        class="info cursor-pointer"
        style="margin-top: 10px"
        v-if="opportunity"
        @click="openOpportunity(opportunity.id)"
      >
        {{ "Realizado na oportunidade:" }}

        <div class="d-flex flex-row">
          <Avatar
            :hasStatus="false"
            :avatarLetter="$service.getFirstLetter(opportunity.name || 'X')"
            avatarColor="#FFFFFF"
            avatarBackground="#109CF1"
          />

          <div class="d-flex flex-column">
            <span class="name"> {{ opportunity.name }} </span>

            <span class="cod" v-if="opportunity.id">
              #{{ opportunity.id }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import Avatar from "../Avatar.vue";

export default {
  name: "TaskReopened",

  components: {
    BCard,
    Avatar,
  },

  props: {
    data: {
      default: null,
    },

    title: {
      type: String,
      default: "",
    },

    endType: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "",
    },

    description: {
      type: String,
      default: "",
    },

    user: {
      type: Object,
    },

    opportunity: {
      default: null,
    },

    fromUserModal: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    hasTaskId() {
      return this.data.id ? true : false;
    },
  },

  methods: {
    openTask() {
      if (!this.hasTaskId) {
        this.$bvToast.toast("Esta tarefa não é direcionável!", {
          title: `Tarefa não direcionavel!`,
          autoHideDelay: 1500,
          variant: "warning",
          toaster: "b-toaster-top-left",
          solid: true,
        });

        return;
      }

      this.$emit("openTask");
    },

    openOpportunity(id) {
      this.$store
        .dispatch("userStore/setOpportunityForHistory", id)
        .then(() => {
          this.$bvModal.show("bv-user-update_opportunity_modal");
        });
    },

    getFormatedType(type) {
      const formatedType = type.replace(" ", "");

      const types = {
        AttemptedContact: "Tentativa de contato",
        Contact: "Contato",
        DigitalService: "Atendimento Digital",
        ExternalVisit: "Visita Externa",
        StreetVisit: "Visita Street",
        SpontaneousVisit: "Visita Espontânea",
        RemoteSales: "Televendas",
        CommercialFollowup: "Acompanhamento comercial",
        CommercialPrevention: "Prevenção comercial",
        SchoolVisit: "Visita Escola",
      };

      return types[formatedType];
    },
  },
};
</script>

<style lang="scss">
.card {
  box-shadow: none;
  border: 1px solid #c4ccd8;
  border-radius: 10px;
  margin: 0 1em 1.5em;
  text-align: left;

  & > .card-body {
    padding: 0rem;

    .header {
      border-bottom: 1px solid #c4ccd8;
      padding: 0.5rem;
      flex-flow: row;
      display: flex;
      justify-content: space-between;

      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #4c5862;
      }
    }

    .footer {
      border-top: 1px solid #c4ccd8;
      padding: 0.5rem;
      flex-flow: row;
      display: flex;
      justify-content: space-between;

      .user {
        flex-flow: row;
        display: flex;

        .info {
          flex-flow: column;
          display: flex;

          .cod {
            color: #afafaf;
            font-size: 0.85em;
          }
        }
      }
    }

    .body {
      padding: 0 0.5em;
      h3 {
        font-weight: 500;
        font-size: 1em;
        line-height: 24px;
        color: #046af3;
        margin: 0px;
      }

      .name {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        color: #000000;
        margin: 0px;
      }

      .info-container {
        display: flex;
        flex-flow: row;
      }
    }
  }
}
</style>
