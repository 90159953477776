<template>
  <div class="d-flex flex-column h-100 text-center">
    <div
      class="d-flex justify-content-between flex-column content-sidebar-header"
    >
      <div class="d-flex flex-row">
        <feather-icon
          class="cursor-pointer my-1 mx-1"
          icon="XIcon"
          size="16"
          @click="cancel"
        />
        <h3 class="mt-2 ml-3 text-dark">Transferência de oportunidade</h3>
      </div>
      <p class="title-muted">
        Você está em {{ workspaceCount }} áreas de trabalho, selecione
        <br />alguma para fazer a transferência da oportunidade.
      </p>
    </div>

    <div class="workspace-list mt-1 px-2">
      <div class="search-box mb-2">
        <span class="search">
          <input-field
            v-model="search"
            placeholder="Buscar"
            @keyup="queueSearch"
          />
          <i class="icon-search" />
        </span>
        <span v-if="hasRequest" class="d-flex justify-content-center my-1">
          <b-spinner />
        </span>
      </div>
      <ul>
        <li
          v-for="(workspace, index) in workspaces"
          :key="workspace.id"
          class="d-flex justify-content-start align-items-center step-click"
          :class="`step${index < 5 ? index + 1 : index + 1 - 5}-border`"
          @click="selectItem(workspace.id)"
        >
          <span class="ml-1">{{ workspace.name || "(sem nome)" }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { BContainer, BFormRadio, BButton, BSpinner } from "bootstrap-vue";
import "vue-select/dist/vue-select.css";
import Confirm from "@/views/components/base/Confirm.vue";
import InputField from "./InputField.vue";
import { errorHandling } from "@/mixins";

export default {
  components: {
    BButton,
    BSpinner,
    BFormRadio,
    BContainer,
    InputField,
    Confirm,
  },

  data() {
    return {
      currentWorkspace: {},
      search: "",
      hasRequest: true,
      queueingSearch: 0,
      workspaces: [],
      workspaceCount: 0,
    };
  },

  mixins: [errorHandling],

  computed: {
    hasSelected() {
      return this.currentWorkspace.id > 0;
    },
  },

  mounted() {
    this.fetchWorkspace();
  },

  methods: {
    queueSearch() {
      window.clearTimeout(this.queueingSearch);
      this.queueingSearch = setTimeout(() => {
        this.fetchWorkspace();
      }, 850);
    },

    fetchWorkspace() {
      const params = [
        {
          query: "limit",
          param: 10,
        },
        {
          query: "search",
          param: this.search,
        },
      ];

      if (this.search.length < 2 && this.search.length > 0) return;

      this.$store
        .dispatch(
          "app/fetchUserWorkspaces",
          this.$service.formatQueryParam(params)
        )
        .then((res) => {
          this.workspaces = res.data.filter((w) => w.role.length > 0);
          if (this.workspaceCount == 0) {
            this.workspaceCount = res.pagination.total_count;
          }
        })
        .catch((error) => {
          this.MIXIN_showError(error.response.data, error.response.data.status);
        })
        .finally(() => {
          this.hasRequest = false;
        });
    },

    async selectItem(id) {
      let selectedWorkspace;

      await this.$store.dispatch("app/getWorkspace", id).then((data) => {
        selectedWorkspace = data;
      });

      this.$emit("selectedWorkspace", selectedWorkspace);
      this.$bvModal.hide("bv-workspace-list");
    },

    async refreshWorkspaces() {
      await this.$store.dispatch("app/fetchUserWorkspaces");
    },

    cancel() {
      this.$bvModal.hide("bv-workspace-list");
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  .search {
    padding: 0px;
    margin: 0px;
    position: relative;
    border: 1px solid #e5e5e5;
    display: flex;

    .input-field-container {
      padding: 0px;
      margin: 0px;
      :deep(input) {
        border: none;
      }
    }
    .icon-search {
      position: absolute;
      right: 10px;
      line-height: 45px;
    }
  }
}
ul {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px;
}

ul > li {
  display: flex;
  border-radius: 6px;
  border-left: 3px solid;
  border: 1px solid;
  margin-bottom: 6px;
  height: 2em;
  list-style: none;
  height: 55px;
  line-height: 55px;
  text-align: center;
  padding: 0 1em;
}

ul > li.step-1 {
  border-color: #333;
}

.step-click {
  cursor: pointer;
}

.user-selector {
  padding: 0.25em;
  border: 1px solid #046af3;
  border-radius: 6px;
}

.circle {
  width: 2em;
  display: inline-flex;
  height: 2em;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #fdf2f2;
  color: #ff2c55;
}

.workspace-list ul {
  li {
    display: flex;
    .custom-radio {
      display: flex;
      align-self: center;
    }
  }
}

$workspace-step1: #046af3 !default;
$workspace-step2: #f8aa00 !default;
$workspace-step3: #9160de !default;
$workspace-step4: #ff2c55 !default;
$workspace-step5: #2ed47a !default;

.step1-border {
  border-color: $workspace-step1;
}
.step1-border svg {
  color: $workspace-step1;
}
.step2-border {
  border-color: $workspace-step2;
}
.step2-border svg {
  color: $workspace-step2;
}
.step3-border {
  border-color: $workspace-step3;
}
.step3-border svg {
  color: $workspace-step3;
}
.step4-border {
  border-color: $workspace-step4;
}
.step4-border svg {
  color: $workspace-step4;
}
.step5-border {
  border-color: $workspace-step5;
}
.step5-border svg {
  color: $workspace-step5;
}
</style>
