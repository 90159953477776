/* eslint-disable no-empty-pattern */
import { $themeBreakpoints } from "@themeConfig";
import Vue from "vue";
import VueCookie from "vue-cookie";
import axios from "axios";
import { instance } from "../../config/index";
import store from "@/store/index";
import router from "../../router";

Vue.use(VueCookie);

const initialState = () => ({
  token: localStorage.getItem("token"),
  windowWidth: 0,
  shallShowOverlay: false,
  user: {},
  workspace: {},
  roles: [],
  hasRouterNavigation: false,
});

const getters = {
  currentBreakPoint: (state) => {
    const { windowWidth } = state;
    if (windowWidth >= $themeBreakpoints.xl) return "xl";
    if (windowWidth >= $themeBreakpoints.lg) return "lg";
    if (windowWidth >= $themeBreakpoints.md) return "md";
    if (windowWidth >= $themeBreakpoints.sm) return "sm";
    return "xs";
  },

  getUserData(state) {
    return state.user;
  },

  getUserRole(state) {
    let userRole = state.workspace.role;

    if (userRole) {
      return userRole;
    }
    return "";
  },

  getRoles(state) {
    return state.roles;
  },

  getUserWorkspaces(state) {
    return state.user.workspaces;
  },

  getUserWorkspace(state) {
    return state.workspace;
  },

  getAllRoles(state) {
    return state.roles;
  },
};

const mutations = {
  UPDATE_WINDOW_WIDTH(state, val) {
    state.windowWidth = val;
  },

  TOGGLE_OVERLAY(state, val) {
    state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
  },

  SET_TOKEN(state, token) {
    state.token = token;
  },

  SET_USER(state, user) {
    state.user = user;
  },

  SET_WORKSPACE(state, workspace) {
    state.workspace = workspace;
  },

  SET_ROLES(state, roles) {
    state.roles = roles;
  },

  SET_HAS_ROUTER_NAVIGATION(state, hasRouterNavigation) {
    state.hasRouterNavigation = hasRouterNavigation;
  },

  SET_LOGOUT(state) {
    const inital = initialState();

    Object.keys(inital).forEach((key) => {
      state[key] = inital[key];
    });
  },
};

const actions = {
  authUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      instance()
        .post("/auth/token", data)
        .then((response) => {
          const res = response.data;
          const token = res.access_token;
          const tokenExpiresIn = `${res.expires_in}s`;
          const workspaceId = res.user.workspaces[0].id;
          const currentWorkspaceData = res.user.workspaces[0];

          if (res.imNic) {
            const imNicToken = res.imNic.access_token;
            const acessCode = res.user.access_code;

            localStorage.setItem("imNicToken", imNicToken);
            localStorage.setItem("acessCode", acessCode);
          }

          localStorage.setItem("token", token);
          localStorage.setItem("currentWorkspace", workspaceId);

          axios.defaults.headers.common.Authorization = token;

          commit("SET_TOKEN", token);
          commit("SET_USER", res.user);
          commit("SET_WORKSPACE", currentWorkspaceData);

          resolve(response);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  async changeCurrentWorkspace({ commit }, currentWorkspace) {
    //guarantees lastest data
    // await dispatch("fetchUserWorkspaces", service.formatQueryParam(parms)).then((data) => {
    //   this.state.app.user.workspaces =  data.data;
    //   const currentWorkspace = data.data.filter((w) => w.id === parms.id)[0];
    //   console.log(currentWorkspace);

    // });

    localStorage.setItem("currentWorkspace", currentWorkspace.id);

    commit("SET_WORKSPACE", currentWorkspace);
  },

  getWorkspace({}, id) {
    return this.state.app.user.workspaces.filter((w) => w.id === id)[0];
  },

  fetchUserWorkspaces({}, parms) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`/users/self/workspaces${parms || ""}`)
        .then((response) => {
          this.state.app.user.workspaces = response.data;
          resolve(response);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  fetchWorkspaceSettings({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/settings${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  fetchWorkspace({}, id) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${id}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  saveWorkspaceSettings({}, workspaceSettings) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .post(`/workspaces/${workspaceId}/settings`, workspaceSettings)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  saveWorkspace({}, workspace) {
    const postUrl = `users/self/workspaces`;

    if (workspace.id) {
      // Update
      return new Promise((resolve, reject) => {
        instance()
          .put(`${postUrl}/${workspace.id}`, workspace)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    }

    // Insert
    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, workspace)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  /* PASSWORD AND INVITATION*/
  requestPasswordReset({}, data) {
    return new Promise((resolve, reject) => {
      instance()
        .put("/auth/request-password-reset", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  putResetPassword({}, data) {
    return new Promise((resolve, reject) => {
      instance()
        .put("/auth/reset-password", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  sendInvitation({}, data) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .post(`workspaces/${workspaceId}/user-invitations`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  getUserInvitation({}, data) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`/user-invitations/${data.id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  postUserInvitation({ commit }, data) {
    return new Promise((resolve, reject) => {
      instance()
        .post(`/user-invitations/${data.id}/fulfill`, data.data)
        .then((response) => {
          const res = response.data;
          const token = res.authentication.access_token;
          const tokenExpiresIn = `${res.authentication.expires_in}s`;
          const workspaceId = res.workspace_id;
          const currentWorkspaceData = res.user.workspaces.find(
            (item) => item.id === workspaceId
          );

          localStorage.setItem("token", token);
          localStorage.setItem("currentWorkspace", workspaceId);

          axios.defaults.headers.common.Authorization = token;

          commit("SET_TOKEN", token);
          commit("SET_USER", res.user);
          commit("SET_WORKSPACE", currentWorkspaceData);

          resolve(response);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  getRoles({ commit }) {
    return new Promise((resolve, reject) => {
      instance()
        .get("/roles")
        .then((response) => {
          const res = response.data;

          commit("SET_ROLES", res);

          resolve(response);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  fetchUsers({}, query) {
    /* TODO get data from id */
    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${this.state.app.workspace.id}/users${query || ""}`)
        .then((response) => resolve(response))
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  fetchUsersFromAnotherWorkspace({}, { workspaceId, query }) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/users${query || ""}`)
        .then((response) => resolve(response))
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  fetchUserWorkspaces({}, query) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`/users/self/workspaces${query || ""}`)
        .then((response) => {
          this.state.app.user.workspaces = response.data;
          resolve(response);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      localStorage.removeItem("token");
      localStorage.removeItem("imNicToken");
      localStorage.removeItem("acessCode");

      delete axios.defaults.headers.common.Authorization;
      commit("SET_LOGOUT");
      store.commit("opportunityStore/SET_LOGOUT");
      router.push(`${"login"}`);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};
