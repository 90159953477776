/* eslint-disable no-empty-pattern */
import Vue from "vue";
import { instance } from "@/config/index.js";

const initialState = () => ({
  log_types_dictionary: [
    { id: "OpportunityCreated", title: "Oportunidade Criada" },
    { id: "OpportunitySalesStageUpdated", title: "Etapa do Funil Alterada" },
    { id: "OpportunityUserUpdated", title: "Transferência de Responsável" },
    { id: "OpportunityDescriptionUpdated", title: "Descrição Alterada" },
    { id: "OpportunityProbabilityUpdated", title: "Qualificação Alterada" },
    { id: "OpportunityPriceCreated", title: "Preço Adicionado" },
    { id: "OpportunityPriceUpdated", title: "Preço Alterado" },
    { id: "OpportunityProductCreated", title: "Produto Adicionado" },
    { id: "OpportunityProductUpdated", title: "Produto Atualizado" },
    { id: "OpportunityClosedWon", title: "Oportunidade Ganha" },
    { id: "OpportunityClosedLost", title: "Oportunidade Perdida" },
    { id: "OpportunityReopened", title: "Oportunidade Reaberta" },
    { id: "OpportunityRecreated", title: "Oportunidade Recriada" },
    { id: "OpportunityTma", title: "Tempo de Atendimento" },
    { id: "TaskCreated", title: "Tarefa Agendada" },
    { id: "TaskClosed", title: "Tarefa Finalizada" },
    { id: "TaskDeleted", title: "Tarefa Deletada" },
    { id: "TaskReopened", title: "Tarefa Reaberta" },
    { id: "TaskRegistry", title: "Tarefa Registrada" },
    { id: "TaskRegistryDeleted", title: "Registro de Tarefa Deletado" },
    { id: "OpportunityTransferred", title: "Oportunidade Transferida" },
    { id: "OpportunityRoomStatusClosed", title: "Sala de Chat Fechada" },
    { id: "TaskRescheduled", title: "Tarefa Reagendada" },
    { id: "ContractCreated", title: "Contrato Criado" },
    { id: "ContractUpdated", title: "Contrato Atualizado" },
    { id: "ContractDeleted", title: "Contrato Deletado" },
    {
      id: "ContractPaymentStatusUpdated",
      title: "Status do Contrato Atualizado",
    },
    { id: "OpportunityCallAttempt", title: "Tentativa de Chamada " },
    { id: "OpportunityCallSuccess", title: "Chamada Bem-Sucedida" },
    { id: "OpportunityRoomStatusReopened", title: "Sala reaberta" },
  ],

  rolesDictionary: [
    // Base roles
    { id: "salesRep", title: "Vendedor" },
    { id: "manager", title: "Gerente" },
    { id: "admin", title: "Administrador" },

    // Roles SGF
    { id: "consultorFranquia", title: "Consultor de Franquia" },
    { id: "gestorFranquia", title: "Gestor de Franquia" },
    { id: "diretorFranquia", title: "Diretor de Franquia" },
    { id: "gerenteComercial", title: "Gerente Comercial" },
    { id: "consultorComercial", title: "Consultor Comercial" },
    { id: "franqueado", title: "Franqueado" },
    { id: "auxiliarTi", title: "Auxiliar de TI" },
    { id: "ceo", title: "CEO" },
    { id: "master", title: "Master" },

    // External roles with specific permissions
    { id: "uraApi", title: "URA Api", isApi: true },
    { id: "imBotApi", title: "Im Bot Api", isApi: true },
  ],

  opportunity_for_history: null,
});

const getters = {
  getLogTypes(state) {
    return state.log_types_dictionary;
  },

  getOpportunityForHistory(state) {
    return state.opportunity_for_history;
  },

  getRoles(state) {
    return state.rolesDictionary;
  },
};

const mutations = {};

const actions = {
  list({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/users${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  getWorkspaces({}, { userId, params }) {
    return new Promise((resolve, reject) => {
      instance()
        .get(`/users/${userId}/workspaces${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  listHierarchically({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/users-in-hierarchy${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  getUserHistory({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/logs${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  getUserLogHistory({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/user-logs${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  setOpportunityForHistory({ state }, id) {
    let opportunity;

    const workspaceId = localStorage.getItem("currentWorkspace");


    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/opportunities/${id}`)
        .then((response) => {
          if (response.data.length === 0) {
            return;
          }

          opportunity = response.data;

          state.opportunity_for_history = opportunity;
        })
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};
