<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <loading id="loading-screen" :data="loadingData" />

    <news-warning v-if="getNewsState" :warning="warning" />

    <dial-bar v-if="externalPhoneCredentials" />

    <modal-faq-videos v-if="getFaqState" />

    <modal-carousel v-if="modalCarouselState" />

    <b-modal-central />

    <router-view />
  </div>
</template>

<script>
import BModalCentral from "./views/components/BModalCentral.vue";
import DialBar from "./views/components/External Phone/DialBar.vue";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Loading from "../src/views/components/Loading.vue";
import ModalCarousel from "./views/components/ModalCarousel";
import ModalFaqVideos from "./views/components/Tutorials/ModalFaqVideos.vue";
import moment from "moment";
import NewsWarning from "./views/components/NewsWarning.vue";
import store from "@/store";
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import useAppConfig from "@core/app-config/useAppConfig";
import Vue from "vue";
import VueCookie from "vue-cookie";
import { useCssVar } from "@vueuse/core";
import { provideToast } from "vue-toastification/composition";

Vue.use(VueCookie);

export default {
  components: {
    BModalCentral,
    DialBar,
    Loading,
    ModalCarousel,
    ModalFaqVideos,
    NewsWarning,
  },

  data() {
    return {
      skinClasses: [],
      warning: null,
    };
  },

  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },

    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },

    loadingData() {
      this.$store.dispatch("whiteLabel/setPlatform");
      const data = this.$store.getters["whiteLabel/getPlatform"];

      return data;
    },

    getFaqState() {
      return this.$store.state.faqVideos.isOpen;
    },

    getNewsState() {
      return this.$store.state.newsWarning.isOpen;
    },

    allWarnings() {
      return this.$store.getters["newsWarning/getAllWarnings"];
    },

    updateTick() {
      return this.$store.state.newsWarning.updateTick;
    },

    externalPhoneCredentials() {
      return this.$store.getters["externalPhone/getUserCredentials"];
    },

    modalCarouselState() {
      return this.$store.getters["modalCarousel/getCarouselState"];
    },
  },

  beforeCreate() {
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },

  created() {
    this.cleanStates();

    this.initialSetup();

    // this.connectToFireBase();
  },

  watch: {
    updateTick() {
      this.checkNews();
    },
  },

  methods: {
    cleanStates() {
      this.$store.getters["opportunityStore/resetState"];
      this.$store.commit("externalPhone/RESET_STATE");
      this.$store.dispatch("modalCarousel/changeShowCarousel", false);
    },

    connectToFireBase() {
      const firebaseConfig = {
        apiKey: "AIzaSyANqtQnZnu7oIZdHpB6o-5H_eYr-2bWItU",
        authDomain: "crm-instituto-mix.firebaseapp.com",
        projectId: "crm-instituto-mix",
        storageBucket: "crm-instituto-mix.appspot.com",
        messagingSenderId: "336794883334",
        appId: "1:336794883334:web:870fa6167fe885ab927a87",
        measurementId: "G-YRYRT8H4LB",
      };

      const app = initializeApp(firebaseConfig);

      this.$store.dispatch("firebase/setApp", app);

      const messaging = getMessaging();

      getToken(messaging, {
        vapidKey:
          "BD8rZjzUHP9JK2F_cXn-yqbVFRbnLqTvf72dDE5kMOi-6K_8fGEf6HpJoimoKWjgKrjQgzAzCmpHZeWBw_AmOFs",
      })
        .then((currentToken) => {
          if (currentToken) {
            console.log(currentToken);
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });

      onMessage(messaging, (payload) => {
        console.log("Message received. ", payload);
      });
    },

    initialSetup() {
      const { skin, skinClasses } = useAppConfig();

      if (skin.value === "dark") document.body.classList.add("dark-layout");

      provideToast({
        hideProgressBar: true,
        closeOnClick: false,
        closeButton: false,
        icon: false,
        timeout: 3000,
        transition: "Vue-Toastification__fade",
      });

      store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);

      this.skinClasses = skinClasses;
    },

    checkNews() {
      const currentPath = window.location.pathname;

      if (this.allWarnings && currentPath != "/login") {
        const expirationDate = new Date();
        expirationDate.setFullYear(expirationDate.getFullYear() + 10);

        let warning;

        this.allWarnings.forEach((element) => {
          const currentDate = moment();
          const warningExpiration = moment(element.expiration, "DD/MM/YYYY");
          const hasPassed = warningExpiration.isSameOrBefore(currentDate);

          warning = localStorage.getItem(element.id);
          this.warning = element;

          if (!warning) {
            localStorage.setItem(element.id, true);

            if (!hasPassed) {
              this.$store.commit(
                "newsWarning/CHANGE_NEWS_MODAL_CONDITION",
                true
              );
            }

            return;
          }
        });
      }
    },
  },
};
</script>
