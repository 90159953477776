import { render, staticRenderFns } from "./ModalCarousel.vue?vue&type=template&id=54d710bd&scoped=true&"
import script from "./ModalCarousel.vue?vue&type=script&lang=js&"
export * from "./ModalCarousel.vue?vue&type=script&lang=js&"
import style0 from "./ModalCarousel.vue?vue&type=style&index=0&id=54d710bd&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54d710bd",
  null
  
)

export default component.exports