<template>
  <div id="list-product" class="d-flex flex-column h-100">
    <div class="d-flex justify-content-between pt-2 header">
      <h3 class="mt-1 mx-auto"><b>Selecionar Colaborador</b></h3>
    </div>
    <div class="d-flex flex-column mt-1 px-2" style="padding-bottom: 75px">
      <b-tabs @activate-tab="toggleType">
        <b-tab :title="this.platform_terms.collaborator_term">
          <ul class="w-100">
            <li class="search">
              <input-field
                v-model="search"
                placeholder="Buscar"
                @keyup="queueSearch"
              />
              <i class="icon-search" />
            </li>
            <li v-if="hasRequest" class="d-flex align-items-center">
              <b-spinner />
            </li>
          </ul>
        </b-tab>

        <b-tab title="Colaboradores" v-if="showManagers">
          <ul class="w-100">
            <li class="search">
              <input-field
                v-model="search"
                placeholder="Buscar"
                @keyup="queueSearch"
              />
              <i class="icon-search" />
            </li>
            <li v-if="hasRequest" class="d-flex align-items-center">
              <b-spinner />
            </li>
          </ul>
        </b-tab>
      </b-tabs>

      <ul class="w-100" v-if="this.items">
        <li
          v-for="(item, index) in items"
          :key="item.id"
          @click="select(item.id)"
        >
          <user-item v-model="items[index]" />
        </li>
      </ul>
      <div class="w-100" v-if="this.showEmptyMessage">
        <h5 class="pt-2">
          <b
            >Nenhum
            {{
              this.isOthers
                ? "colaborador"
                : this.platform_terms.collaborator_term
            }}
            ativo nesse Workspace.</b
          >
        </h5>
        <h6 class="pt-1">
          <b>Dica:</b> O
          {{
            this.isOthers
              ? "colaborador"
              : this.platform_terms.collaborator_term
          }}
          se torna ativo no CRIM após o seu primeiro acesso na platforma.
        </h6>
        <img src="@/assets/images/no-data-illustration.svg" />
      </div>
      <div
        v-if="
          this.search.length > 3 && !this.items.length && !this.showEmptyMessage
        "
      >
        <h4 class="mt-4">
          Nenhum
          {{
            this.isOthers
              ? "colaborador"
              : this.platform_terms.collaborator_term
          }}
          encontrado para essa busca.
        </h4>
        <img src="@/assets/images/no-data-illustration.svg" />
      </div>
    </div>
    <div class="cancelButtonDiv">
      <b-button @click="cancel" class="cancelButton"> Cancelar </b-button>
    </div>
  </div>
</template>
<script>
import { BButton, BSpinner, BTabs, BTab } from "bootstrap-vue";
import InputField from "./InputField.vue";
import UserItem from "./UserItem.vue";

export default {
  components: { InputField, BButton, UserItem, BSpinner, BTabs, BTab },

  data() {
    return {
      search: "",
      items: [],
      hasRequest: false,
      queueingSearch: 0,
      isOthers: false,
      showEmptyMessage: false,
    };
  },

  props: {
    showManagers: {
      type: Boolean,
      default: true,
    },

    workspaceId: {
      type: [Number, String],
    },
  },

  created() {
    this.hasRequest = true;

    this.$store.dispatch("whiteLabel/setPlatform");
    this.fetchUsers();
  },

  computed: {
    allRoles() {
      return this.$store.getters["userStore/getRoles"];
    },

    platform_terms() {
      const platformData = this.$store.getters["whiteLabel/getPlatform"];

      return platformData.terms;
    },
  },

  methods: {
    queueSearch() {
      window.clearTimeout(this.queueingSearch);
      this.hasRequest = true;
      this.queueingSearch = setTimeout(() => {
        this.fetchUsers();
      }, 1000);
    },

    addUserParamsForSelector(params) {
      const platformData = this.$store.getters["whiteLabel/getPlatform"];
      const mainCollaborator = platformData.main_collaborator;

      if (this.isOthers) {
        this.allRoles.forEach((element) => {
          if (element.id != mainCollaborator && !element.isApi) {
            params.push({ query: "role" + "[]", param: element.id });
          }
        });
      } else {
        params.push({ query: "role", param: mainCollaborator });
      }

      return params;
    },

    async fetchUsers() {
      let params = [
        {
          query: "limit",
          param: 100,
        },
        {
          query: "search",
          param: this.search,
        },
      ];

      params = this.addUserParamsForSelector(params);

      if (this.search.length < 3 && this.search.length > 0) {
        this.hasRequest = false;
        return;
      }

      await this.$store
        .dispatch("app/fetchUsersFromAnotherWorkspace", {
          workspaceId: this.workspaceId,
          query: this.$service.formatQueryParam(params),
        })
        .then((p) => {
          this.items = p.data;
          this.hasRequest = false;

          if (
            this.items.length == 0 &&
            !this.search.length &&
            !this.hasRequest
          ) {
            this.showEmptyMessage = true;
          } else {
            this.showEmptyMessage = false;
          }
        });
    },

    cancel() {
      this.$bvModal.hide("bv-user-list");
    },

    select(selectedId) {
      const selectedItem = this.items.filter(
        (item) => item.id === selectedId
      )[0];

      this.$emit("selectedUser", selectedItem);
      this.$bvModal.hide("bv-user-list");
    },

    toggleType() {
      this.isOthers = !this.isOthers;
      this.hasRequest = true;
      this.showEmptyMessage = false;
      this.fetchUsers();
    },
  },
};
</script>

<style lang="scss" scoped>
#list-product {
  :deep(.nav-tabs) {
    .nav-item {
      display: flex;
      flex-flow: column;
      flex-grow: 1;

      &:first-child {
        .nav-link {
          border-right: none;
        }
      }

      .nav-link {
        border: 1px solid #c4ccd8;
        &.active {
          padding-bottom: calc(8.54px + 1px);
          border-bottom: none;
        }
        &:after {
          border-bottom: 3px solid #046af3 !important;
          background: transparent !important;
        }
      }
    }
  }

  ul {
    border: 1px solid #e5e5e5;
    border-bottom: none;
    padding: 0;
    margin: 0;

    li.search {
      padding: 0px;
      margin: 0px;

      .input-field-container {
        padding: 0px;
        margin: 0px;
        :deep(input) {
          border: none;
        }
      }
      .icon-search {
        position: absolute;
        right: 40px;
        line-height: 45px;
      }
    }
    li {
      list-style: none;
      border-bottom: 1px solid #e5e5e5;
      padding: 9px;
      display: flex;
      flex-direction: column;

      &:hover {
        font-weight: 500;
        box-shadow: 1px 1px 4px 1px #a5a5a53d;
      }
    }
  }
}

.cancelButtonDiv {
  justify-content: center;
  display: flex;
  position: fixed;
  max-width: 500px;
  border: 1px solid #d9d9d9;
  bottom: 0;
  width: 100%;
  padding: 5px;
  background: #fff;
  .cancelButton {
    background: transparent !important;
    color: black !important;
    border: none !important;
    box-shadow: none !important;
  }
}
</style>
