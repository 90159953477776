const initialState = () => ({
  filters: {
    start_timestamp: null,
    end_timestamp: null,
    dateType: "updated_at",
    start_at: null,
    end_at: null,
    user: [],
    user_id: [],
    status: null,
    type: null,
  },

  date_types: [
    { id: "created_at", title: "Data de criação" },
    { id: "updated_at", title: "Data de atualização" },
  ],

  status: [
    { id: true, name: "Disponível" },
    { id: false, name: "Indisponível" },
  ],

  types: [
    { id: "Routine", name: "Rotina" },
    { id: "Action", name: "Ação" },
  ],
});

const getters = {
  getFilters(state) {
    return state.filters;
  },

  getDateType(state) {
    return state.date_types;
  },

  getStatus(state) {
    return state.status;
  },

  getTypes(state) {
    return state.types;
  },

  haveAnyActiveFilters(state) {
    if (state.filters.start_timestamp) {
      return true;
    }

    if (state.filters.end_timestamp) {
      return true;
    }

    if (state.filters.start_at) {
      return true;
    }

    if (state.filters.end_at) {
      return true;
    }

    if (state.filters.user_id.length > 0) {
      return true;
    }

    if (state.filters.status) {
      return true;
    }

    if (state.filters.type) {
      return true;
    }

    return false;
  },
};

const actions = {
  resetState({ state }) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },

  cleanValues({ state }) {
    state.filters = {
      start_timestamp: null,
      end_timestamp: null,
      dateType: "created_at",
      start_at: null,
      end_at: null,
      user: [],
      user_id: [],
      status: null,
      type: null,
    };

    return state.filters;
  },

  setFilters({ state }, filters) {
    state.filters = filters;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
};
