import { render, staticRenderFns } from "./OpportunityRoomStatusReopened.vue?vue&type=template&id=b1b9ce0c&"
import script from "./OpportunityRoomStatusReopened.vue?vue&type=script&lang=js&"
export * from "./OpportunityRoomStatusReopened.vue?vue&type=script&lang=js&"
import style0 from "./OpportunityRoomStatusReopened.vue?vue&type=style&index=0&id=b1b9ce0c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports