const initialState = () => ({
  app: {},
});

const getters = {
  getApp(state) {
    return state.app;
  },
};

const actions = {
  setApp({ state }, app) {
    state.app = app;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
};
