import Vue from "vue";
import Vuex from "vuex";
import VueCookie from "vue-cookie";
import createPersistedState from "vuex-persistedstate";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import opportunityStore from "@/store/opportunity";
import dashboards from "@/store/dashboards";
import calendarStore from "@/store/calendar";
import userStore from "@/store/user";
import contactStore from "@/store/contact";
import omnichannel from "./omnichannel";
import navMenu from "./nav-menu";
import calendarFilter from "./calendar-filter";
import pipelineFilter from "./pipeline-filter";
import dashboardFilter from "./dashboard-filter";
import faqVideos from "./faq-videos";
import contract from "./contract";
import planningCalendarFilter from "./planning-calendar-filter";
import whiteLabel from "./white-label";
import planning from "./planning";
import planningTemplateFilter from "./planning-template-filter";
import omnichannelFilter from "./omnichannel-filter";
import newsWarning from "./news-warning";
import externalPhone from "./external-phone";
import firebase from "./firebase";
import modalCarousel from "./modal-carousel";
import bmodalEvents from "./bmodal-events";

Vue.use(VueCookie);
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    opportunityStore,
    dashboards,
    calendarStore,
    userStore,
    contactStore,
    omnichannel,
    navMenu,
    calendarFilter,
    pipelineFilter,
    dashboardFilter,
    faqVideos,
    contract,
    whiteLabel,
    planning,
    planningCalendarFilter,
    planningTemplateFilter,
    omnichannelFilter,
    newsWarning,
    externalPhone,
    firebase,
    modalCarousel,
    bmodalEvents,
  },

  strict: process.env.DEV,
  plugins: [createPersistedState()],
});
