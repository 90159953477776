/* eslint-disable no-empty-pattern */
import Vue from "vue";
import { instance } from "@/config/index.js";

const initialState = () => ({
  phone: null,
  session: null,
  status: null,

  dialing: false,
  paused: false,

  startCallTick: false,
  stopCallTick: false,

  userCredentials: null,
  rowId: null,

  showBar: false,
});

const getters = {
  getPhoneConnection(state) {
    return state.phone;
  },

  getSession(state) {
    return state.session;
  },

  getPhoneStatus(state) {
    return state.status;
  },

  getIsDialing(state) {
    return state.dialing;
  },

  getIsPaused(state) {
    return state.paused;
  },

  getStartCallTick(state) {
    return state.startCallTick;
  },

  getStopCallTick(state) {
    return state.stopCallTick;
  },

  getUserCredentials(state) {
    return state.userCredentials;
  },

  getRowId(state) {
    return state.rowId;
  },
};

const actions = {
  resetStore({ commit }) {
    commit("RESET_STATE");
  },

  endRowViaStore({ commit, dispatch }, rowId) {
    dispatch("rowStopEvent", rowId);

    commit("CHANGE_IS_DIALING", false);
  },

  getSipCredentials({}, userId) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    return new Promise((resolve, reject) => {
      instance()
        .get(`/users/${userId}/workspaces/${workspaceId}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  createSipRow({}, rowData) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    const postUrl = `/workspaces/${workspaceId}/batch-calls`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, rowData)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  rowStartEvent({}, rowId) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    const postUrl = `/workspaces/${workspaceId}/batch-calls/${rowId}/start`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  rowPauseEvent({}, rowId) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    const postUrl = `/workspaces/${workspaceId}/batch-calls/${rowId}/pause`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  rowStopEvent({}, rowId) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    const postUrl = `/workspaces/${workspaceId}/batch-calls/${rowId}/stop`;

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },
};

const mutations = {
  CHANGE_SIP(state, sipConnection) {
    state.phone = sipConnection;
  },

  CHANGE_SESSION(state, session) {
    state.session = session;
  },

  CHANGE_STATUS(state, status) {
    state.status = status;
  },

  CHANGE_IS_DIALING(state, boolVar) {
    state.dialing = boolVar;
  },

  CHANGE_IS_PAUSED(state, boolVar) {
    state.paused = boolVar;
  },

  CHANGE_USER_CREDENTIALS(state, credentials) {
    state.userCredentials = credentials;
  },

  CHANGE_ROW_ID(state, rowId) {
    state.rowId = rowId;
  },

  CHANGE_SHOW_BAR(state, showBar) {
    state.showBar = showBar;
  },

  TRIGGER_START_CALLS(state, boolVar) {
    state.startCallTick = boolVar;
  },

  TRIGGER_END_CALL(state) {
    state.stopCallTick = !state.stopCallTick;
  },

  RESET_STATE(state) {
    const initial = initialState();

    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  actions,
  getters,
};
