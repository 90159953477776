/* eslint-disable no-empty-pattern */
import Vue from "vue";
import { instance } from "@/config/index.js";

const initialState = () => ({
  defaultActions: [
    {
      id: "AttemptedContact",
      title: "Tentativa de contato",
      hasTpo: true,
    },
    {
      id: "Contact",
      title: "Contato Telefônico",
      hasTpo: true,
    },
    {
      id: "DigitalService",
      title: "Atendimento Digital",
      hasTpo: true,
    },
    {
      id: "ExternalVisit",
      title: "Visita Externa",
      hasTpo: true,
    },
    {
      id: "SchoolVisit",
      title: "Visita Escola",
      hasTpo: true,
    },
    {
      id: "StreetVisit",
      title: "Visita Street",
      hasTpo: true,
    },
    {
      id: "SpontaneousVisit",
      title: "Visita Espontânea",
      hasTpo: true,
    },
    {
      id: "RemoteSales",
      title: "Televendas",
      hasTpo: true,
    },
    {
      id: "CommercialFollowup",
      title: "Acompanhamento comercial",
      hasTpo: false,
    },
    {
      id: "CommercialPrevention",
      title: "Prevenção comercial",
      hasTpo: false,
    },
  ],

  girassol: [
    {
      id: "AttemptedContact",
      title: "Tentativa de contato",
      hasTpo: true,
    },
    {
      id: "Contact",
      title: "Contato Telefônico",
      hasTpo: true,
    },
    {
      id: "DigitalService",
      title: "Atendimento Digital",
      hasTpo: true,
    },
    {
      id: "ExternalVisit",
      title: "Visita Externa",
      hasTpo: true,
    },
    {
      id: "SchoolVisit",
      title: "Visita Interna",
      hasTpo: true,
    },
    {
      id: "StreetVisit",
      title: "Visita Street",
      hasTpo: true,
    },
    {
      id: "SpontaneousVisit",
      title: "Visita Espontânea",
      hasTpo: true,
    },
    {
      id: "CommercialFollowup",
      title: "Acompanhamento comercial",
      hasTpo: false,
    },
  ],
});

const getters = {
  getActions(state, getters, rootState) {
    const platformData = rootState.whiteLabel.platform;
    const platformCalendarActionType = platformData.calendar_actions;

    const possibleActions = {
      default: state.defaultActions,
      girassol: state.girassol,
    };

    return possibleActions[platformCalendarActionType];
  },

  getEndedTypes({}) {
    return [
      {
        id: "Whatsapp",
        title: "WhatsApp",
      },

      {
        id: "PhoneCall",
        title: "Ligação",
      },

      {
        id: "InpersonMeeting",
        title: "Presencial",
      },

      {
        id: "Email",
        title: "E-mail",
      },
      {
        id: "Other",
        title: "Outro",
      },
    ];
  },
};

const mutations = {};

const actions = {
  save({}, task) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    // const taskId = 1
    const postUrl = `/workspaces/${workspaceId}/tasks`;

    if (task.id) {
      // Update
      return new Promise((resolve, reject) => {
        instance()
          .put(`${postUrl}/${task.id}`, task)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    }

    // Insert
    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, task)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  update({}, task) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/tasks/${task.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(postUrl, task)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  delete({}, task) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/tasks/${task.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .delete(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchTasks({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/tasks${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  fetchOneTask({}, taskId) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/tasks/${taskId}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  endTask({}, task) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const postUrl = `/workspaces/${workspaceId}/tasks/${task.id}/end`;

    return new Promise((resolve, reject) => {
      instance()
        .put(postUrl, task)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};
