<template>
  <div class="vh-100 d-flex flex-column align-items-center">
    <h3 class="pt-2">Realizar Ligação</h3>

    <p
      class="text-center px-3 w-75 pb-1"
      v-if="opportunity.contact.phone_number"
    >
      Realize uma chamada para essa oportunidade.
    </p>

    <p class="text-center px-2 w-75 pb-1" v-else>
      Atualmente essa oportunidade não possui número de telefone integrado.
    </p>

    <b-button
      v-if="opportunity.contact.phone_number"
      @click="openPhone(opportunity.contact.phone_number)"
      class="phone-chat-button"
    >
      <div>
        <img src="@/assets/images/icons/phone-chat-icon.svg" />
      </div>

      Ligar para oportunidade
    </b-button>

    <img
      v-if="opportunity.contact.phone_number"
      src="@/assets/images/phone-illustration.svg"
      class="w-100 ml-2 image-resolution"
    />

    <img
      v-else
      src="@/assets/images/phone-illustration-gray.svg"
      class="w-100 mx-auto mt-1"
    />
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import { openPhoneChannel } from "@/services/callService";

export default {
  components: {
    BButton,
  },
  props: {
    opportunity: {
      type: Object,
      default: null,
      required: true,
    },
  },
  methods: {
    openPhone(phone) {
      openPhoneChannel(phone);
      this.$emit("call");
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-chat-button {
  background: #046af3 !important;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 257px;

  div {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    margin-right: 7px;
    background: #fff;

    img {
      width: 14px;
    }
  }
}

.image-resolution {
  max-width: 400px;
}
</style>
