<template>
  <ValidationProvider
    v-slot="{ errors, required }"
    tag="div"
    :vid="vid"
    :name="inputName"
    :rules="!isDisabled ? rules : ''"
    class="input-field-container"
    :class="{
      'has-icon': isSearch || (isPassword() && !!innerValue),
      search: isSearch,
    }"
  >
    <div class="input-container">
      <input
        ref="input"
        v-model="innerValue"
        v-mask="mask"
        masked="false"
        :class="{ error: errors[0], default: !errors[0], disabled: isDisabled }"
        :type="inputType"
        :value="value"
        :maxlength="maxLength"
        :placeholder="isSearch ? placeholder : ' '"
        autocomplete="off"
        :disabled="isDisabled"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
        @keyup="handleKeyup"
        @keypress="checkInput($event)"
        @keyup.enter="checkInput($event)"
      />

      <label
        v-if="!isSearch"
        :class="{ disabled: isDisabled, error: errors[0] }"
      >
        {{ placeholder }} {{ required ? "*" : "" }}
      </label>

      <div
        v-if="isPassword() && !!innerValue"
        class="icon-container"
        @click="toggleVisibility"
      >
        <i v-if="visibility" class="icon-eye-slash" />

        <i v-else class="icon-eye" />
      </div>

      <div v-if="isSearch" class="icon-container" @click="searchClick">
        <i class="icon-search" />
      </div>
    </div>

    <div v-if="!isDisabled" class="errors">
      <span>{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { mask } from "vue-the-mask";

export default {
  name: "InputFile",

  components: {
    ValidationProvider,
  },

  directives: {
    mask: (el, binding) => {
      if (!binding.value) return;
      mask(el, binding);
    },
  },

  props: {
    type: {
      type: String,
      default: "text",
      validator(value) {
        return [
          "url",
          "text",
          "password",
          "tel",
          "search",
          "number",
          "email",
          "money",
        ].includes(value);
      },
    },

    vid: {
      type: String,
      default: undefined,
    },

    placeholder: {
      type: String,
      default: "Placeholder",
    },

    mask: {
      type: [String, Array],
      required: false,
      default: null,
    },

    rules: {
      type: [Object, String],
      default: "",
    },

    value: {
      type: [String, Number],
      default: "",
    },

    maxLength: {
      type: Number,
      required: false,
    },

    name: {
      type: String,
      required: false,
      default: "name",
    },

    hasFocus: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isSearch: {
      type: Boolean,
      required: false,
    },

    loginViaEnter: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      innerValue: "",
      visibility: false,
      inputType: "text",
      focusStatus: false,
      content: this.value,
      inputName: "",
      hasSize: null,
      hasNumber: null,
      hasSpecialCharacter: null,
      hasUpperLowCase: null,
    };
  },

  watch: {
    innerValue(value) {
      this.$emit("input", value);
    },

    value(value) {
      if (value !== this.innerValue) {
        this.innerValue = value;
      }
    },
  },

  created() {
    this.inputType = this.type;

    if (this.value) {
      this.innerValue = this.value;
    }
  },

  mounted() {
    if (this.name) this.inputName = this.name;
    else this.inputName = this.placeholder.toLowerCase();
  },

  methods: {
    checkInput(event) {
      if (!this.loginViaEnter) {
        return;
      }

      if (event.key == "Enter" || event.keyCode === 13 || event.which === 13) {
        this.$emit("login");
      }
    },

    handleFocus() {
      this.focusStatus = true;
    },

    handleBlur() {
      this.focusStatus = false;
      this.$emit("blur", true);
    },

    handleChange() {
      this.$emit("change", true);
    },

    handleKeyup() {
      this.$emit("keyup", true);
    },

    isFocus() {
      return this.focusStatus;
    },

    isPassword() {
      return this.type === "password";
    },

    toggleVisibility() {
      if (this.visibility) {
        this.visibility = false;
        this.inputType = "password";
      } else {
        this.visibility = true;
        this.inputType = "text";
      }
    },

    searchClick() {
      this.$emit("searchClick");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/components/input-field.scss";
</style>
