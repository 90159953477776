import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import VueCookie from "vue-cookie";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import money from "v-money";
import Service from "@/services/service";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// Register the component globally
import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
// Axios Mock Adapter
// import '@/@fake-db/db'

const faviconLink = document.getElementById("dinamic-favicon");

store.dispatch("whiteLabel/setPlatform");
let platformData = store.getters["whiteLabel/getPlatform"];

faviconLink.href = platformData.favicon;

Vue.prototype.moment = moment;

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VueCookie);

// Composition API
Vue.use(VueCompositionAPI);

// Money default
Vue.use(money, {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  precision: 2,
  masked: false,
});

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.directive("ripple", Ripple);

Vue.config.productionTip = false;

Vue.prototype.$service = Service;

if (
  window.location.host != "localhost:8080" &&
  window.location.host != "crm.imlabs.local:8080"
) {
  Sentry.init({
    Vue,
    dsn: "https://98750904b4fc47dc87e3937200559ed9@sentry.institutomix.com.br/10",
    integrations: [
      new BrowserTracing({
        beforeNavigate: (context) => ({
          ...context,
          name: window.location.pathname,
        }),
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
