<template>
  <div
    @dragover.prevent
    @dragleave.prevent="handleDragLeave($event)"
    @drop="dropHandler($event)"
    :class="hasDraggable ? 'draggable-box white-box' : 'white-box'"
  >
    <floating-quick-messages
      v-if="floatingQuickMessagesState"
      @quickMessageSelected="getQuickMessageAndCloseOptions($event)"
      @close="changeQuickMessagesBoxState(false)"
      :message="newMessage"
    />

    <div v-if="isOpenRoom" v-show="!hasDraggable" class="input-box">
      <v-emoji-picker
        v-if="showEmojiSelection"
        @select="selectEmoji($event)"
        id="emojiSelector"
        class="emoji-selector"
      />

      <feather-icon
        v-if="!recording && !mobilePausedRecording"
        @click="displayEmojiSelector()"
        id="emojiSelector"
        icon="SmileIcon"
        size="20"
        style="margin-right: 5px"
        color="#14223B"
      />

      <input
        v-if="!recording && !mobilePausedRecording"
        @keydown="checkUserInput($event)"
        @input="checkFormMobileInput($event)"
        @keyup.enter="checkMobileEnter($event)"
        v-model="newMessage"
        id="omnichannelNewMessage"
        type="text"
        placeholder="Mensagem..."
        autocomplete="off"
      />

      <i
        v-if="!recording && !mobilePausedRecording && !isSendingFile"
        @click="getDocument()"
        class="icon-clip mr-1"
      />

      <div
        v-if="!recording && !mobilePausedRecording && isSendingFile"
        class="mr-1"
      >
        <b-spinner style="color: #046af3" />
      </div>

      <input
        @change="sendMessage()"
        type="file"
        id="documentInput"
        style="display: none"
        multiple
      />

      <div v-if="recording || mobilePausedRecording" class="audio-box">
        <feather-icon
          @click="clearAudio()"
          icon="Trash2Icon"
          size="22"
          style="cursor: pointer"
          color="#FF3E33"
        />

        <div class="sending-instruction">Clique para enviar</div>

        <div class="timer-recorder">
          <div class="green-ring" />

          <div>
            {{ formatTime(timer) }}
          </div>
        </div>
      </div>

      <div
        @click="getButtonActionByClick()"
        @touchstart="getButtonActionByTouch()"
        @touchend="getButtonActionByTouch()"
        id="inputButton"
        class="rounded-enter"
      >
        <i
          v-if="
            (newMessage && newMessage.length) ||
            recording || mobilePausedRecording
          "
          class="icon-paper-plane"
          style="
            font-size: 19px;
            margin-left: 2px;
            margin-top: 1px;
            color: white;
          "
        />

        <feather-icon
          v-else
          color="#ffffff"
          id="recordAudio"
          size="21"
          icon="MicIcon"
        />
      </div>
    </div>

    <div
      v-if="!isOpenRoom && $service.isNumeric(opportunity.remote_id)"
      class="input-box"
    >
      <div class="responsible-avatar">
        <avatar
          :avatarLetter="$service.getFirstLetter(opportunity.name)"
          :hasBorder="true"
          :hasStatus="false"
          :size="2"
          avatarColor="#046AF3"
        />

        {{ opportunity.name }}
      </div>

      <b-button
        @click="reopenImbotRoom()"
        class="reopen-button"
        variant="primary"
      >
        <span v-if="!hasRoomOpenRequest">Abrir Sala</span>

        <b-spinner v-else variant="light" small />
      </b-button>
    </div>
  </div>
</template>

<script>
import Avatar from "@/views/components/Avatar.vue";
import { BButton, BSpinner } from "bootstrap-vue";
import FloatingQuickMessages from "./FloatingQuickMessages.vue";
import Recorder from "opus-recorder";
import encoderPath from "opus-recorder/dist/encoderWorker.min.js";
import { VEmojiPicker } from "v-emoji-picker";

export default {
  components: {
    Avatar,
    BButton,
    BSpinner,
    FloatingQuickMessages,
    VEmojiPicker,
  },

  props: {
    opportunity: {
      type: Object,
      required: true,
      default: {},
    },
  },

  data() {
    return {
      floatingQuickMessagesState: false,

      lastKey: null,
      newMessage: "",
      filesArray: [],

      audioBlob: null,
      audioChunks: [],
      mediaRecorder: null,
      recording: false,

      timer: 0,
      timerLoop: null,

      mobilePausedRecording: false,

      startTime: null,
      endTime: null,
      touchTime: null,

      isSendingFile: false,

      showEmojiSelection: false,

      hasRoomOpenRequest: false,
    };
  },

  computed: {
    isOpenRoom() {
      return this.opportunity.room_status == "Open";
    },

    quickMessages() {
      return this.$store.getters["omnichannel/getQuickMessages"];
    },

    hasDraggable() {
      return this.$store.getters["omnichannel/getIsHovering"];
    },

    isMobile() {
      if (window.innerWidth <= 800) {
        return true;
      } else {
        return false;
      }
    },
  },

  mounted() {
    this.$store.dispatch("omnichannel/changeHoveringState", false);

    this.createRecorder();
  },

  watch: {
    isOpenRoom() {
      if (this.isOpenRoom) {
        this.hasRoomOpenRequest = false;
      }
    },
  },

  methods: {
    getDocument() {
      document.getElementById("documentInput").click();
    },

    changeQuickMessagesBoxState(state) {
      this.floatingQuickMessagesState = state;
    },

    handleDragLeave(event) {
      this.$store.dispatch("omnichannel/changeHoveringState", false);
    },

    startTimer() {
      if (!this.timerLoop) {
        this.timer = 0;

        this.timerLoop = setInterval(this.updateTimer, 1000);
      }
    },

    stopTimer() {
      clearInterval(this.timerLoop);
      this.timerLoop = null;
    },

    updateTimer() {
      this.timer++;
    },

    hasParentWithId(element, parentId) {
      let currentElement = element;

      while (currentElement && currentElement !== document.body) {
        if (currentElement.id === parentId) {
          return true;
        }

        currentElement = currentElement.parentNode;
      }

      return false;
    },

    displayEmojiSelector() {
      this.showEmojiSelection = !this.showEmojiSelection;

      if (this.showEmojiSelection) {
        const clickHandler = (e) => {
          if (!this.hasParentWithId(e.target, "emojiSelector")) {
            this.showEmojiSelection = false;

            document.removeEventListener("click", clickHandler);
          }
        };

        document.addEventListener("click", clickHandler);
      }
    },

    selectEmoji(emoji) {
      this.newMessage += emoji.data;
    },

    getButtonActionByClick() {
      if (
        (this.newMessage && this.newMessage.length) ||
        this.mobilePausedRecording
      ) {
        this.sendMessage();
      } else if (this.isMobile) {
        return;
      } else {
        this.recordAudio();
      }
    },

    getButtonActionByTouch() {
      if (this.isMobile && !this.mobilePausedRecording && !this.recording && !this.newMessage && !this.newMessage.length) {
        this.recordAudio();
      } else if (this.isMobile && this.recording) {
        this.recordAudio();
      }
    },

    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);

      const remainingSeconds = seconds % 60;

      return `
      ${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}
      `;
    },

    clearInputField() {
      this.filesArray = [];

      const documentInput = document.getElementById("documentInput");

      documentInput.value = "";

      if (documentInput.value) {
        documentInput.type = "text";
        documentInput.type = "file";
      }
    },

    checkForQuickMessages() {
      const matchMask = this.newMessage.match(/\/\w+/g);

      if (matchMask) {
        const command = matchMask[0];

        this.quickMessages.forEach((element) => {
          if (element.id == command) {
            this.newMessage = element.message;

            return;
          }
        });
      }

      this.changeQuickMessagesBoxState(false);
    },

    getQuickMessageAndCloseOptions(event) {
      const words = this.newMessage.split(" ");

      if (words.length > 1) {
        words[words.length - 1] = event.message;

        this.newMessage = words.join(" ");
      } else {
        this.newMessage = event.message;
      }

      this.changeQuickMessagesBoxState(false);

      const inputField = document.getElementById("omnichannelNewMessage");

      inputField.focus();
    },

    clearAudio() {
      if (this.isMobile) {
        this.clearInputField();

        this.timer = 0;
        this.mobilePausedRecording = false;
        this.recording = false;
      } else {
        this.audioBlob = null;

        this.audioChunks = [];

        this.recording = false;
        this.mobilePausedRecording = false;

        this.timer = 0;

        this.clearInputField();
        this.stopTimer();
      }
    },

    checkFormMobileInput(event) {
      if (window.innerWidth > 800) {
        return;
      }

      if (
        (event.inputType == "deleteContentBackward" && this.lastKey == "/") ||
        event.data == " "
      ) {
        this.checkForQuickMessages();
      }

      if (this.newMessage) {
        this.lastKey = this.newMessage.charAt(this.newMessage.length - 1);
      }

      if (event.data === "/") {
        this.changeQuickMessagesBoxState(true);
      }
    },

    checkMobileEnter(event) {
      if (window.innerWidth > 800) {
        return;
      }

      if (event.key == "Enter" || event.keyCode === 13 || event.which === 13) {
        this.sendMessage();
      }
    },

    checkUserInput(event) {
      if (window.innerWidth < 800) {
        return;
      }

      if (event.key == "Enter" || event.keyCode === 13 || event.which === 13) {
        this.sendMessage();
      }

      if (
        event.key === "Backspace" ||
        event.code === "Backspace" ||
        event.keyCode === 8
      ) {
        const inputElement = event.target;

        const inputValue = inputElement.value;

        const cursorPosition = inputElement.selectionStart;

        if (
          cursorPosition > 0 &&
          inputValue.charAt(cursorPosition - 1) === "/" &&
          inputValue.charAt(cursorPosition) === ""
        ) {
          this.changeQuickMessagesBoxState(false);
        }
      }

      if (
        event.key === "/" ||
        event.code === "Slash" ||
        event.keyCode === 191
      ) {
        this.changeQuickMessagesBoxState(true);
      }

      if (event.key == " " || event.code == "Space" || event.keyCode == 32) {
        this.checkForQuickMessages();
      }
    },

    dropHandler(event) {
      event.preventDefault();

      const dataTransfer = event.dataTransfer;
      const files = dataTransfer.files;

      if (files.length > 0) {
        const fileInput = document.getElementById("documentInput");
        const dropedFile = new DataTransfer();

        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const uniqueTimestamp = Date.now() + i;

          dropedFile.items.add(
            new File([file], file.name + uniqueTimestamp, {
              type: file.type,
            })
          );
        }

        fileInput.files = dropedFile.files;
        this.sendMessage();
      }

      this.$store.dispatch("omnichannel/changeHoveringState", false);
    },

    createRecorder() {
      const configs = {
        encoderPath,
        numberOfChannels: 1,
        encoderSampleRate: 48000,
        resampleQuality: 7,
      };

      this.mediaRecorder = new Recorder(configs);

      this.settingMediaRecorderEvents();
    },

    settingMediaRecorderEvents() {
      this.mediaRecorder.onstart = () => {
        this.mediaRecorderOnStart();
      };

      this.mediaRecorder.ondataavailable = (data) => {
        this.mediaRecorderOnDataAvailable(data);
      };

      this.mediaRecorder.onstop = (data) => {
        this.mediaRecorderOnStop(data);
      };
    },

    mediaRecorderOnStart() {
      this.audioBlob = null;
      this.audioChunks = [];
    },

    mediaRecorderOnDataAvailable(data) {
      this.audioChunks.push(data);
    },

    reopenImbotRoom() {
      if (this.hasRoomOpenRequest) {
        return;
      }

      this.hasRoomOpenRequest = true;

      this.$store
        .dispatch("omnichannel/reopenChat", this.opportunity.id)
        .then(() => {
          this.$emit("roomReopened");
        })
        .catch((error) => {
          console.log(error);

          this.$bvToast.toast(error.data.message, {
            title: `Sala não reaberta!`,
            autoHideDelay: 3000,
            variant: "danger",
            toaster: "b-toaster-top-left",
            solid: true,
          });

          this.hasRoomOpenRequest = false;
        });
    },

    mediaRecorderOnStop() {
      const elapsedTime = this.endTime - this.startTime;

      this.touchTime = elapsedTime / 1000;

      this.audioBlob = new Blob(this.audioChunks, {
        type: "audio/ogg",
      });

      const fileInput = document.getElementById("documentInput");
      const audioFileList = new DataTransfer();
      const uniqueTimestamp = Date.now();

      audioFileList.items.add(
        new File([this.audioBlob], "audio_" + uniqueTimestamp, {
          type: "audio/ogg",
        })
      );

      fileInput.files = audioFileList.files;

      if (this.touchTime < 1) {
        this.clearAudio();
      } else if (this.isMobile) {
        this.mobilePausedRecording = true;
      } else {
        this.newMessage = null;

        this.sendMessage();
      }
    },

    recordAudio() {
      if (!this.recording) {
        this.recording = true;
        this.startTime = new Date().getTime();
        this.mobilePausedRecording = false;

        this.mediaRecorder.start();
        this.startTimer();
      } else {
        this.endTime = new Date().getTime();

        if (this.mediaRecorder) {
          this.mediaRecorder.stop();
        }

        this.stopTimer();
        this.recording = false;
      }
    },

    sendMessage() {
      const newMessage = this.newMessage;
      const now = new Date();
      const formatedDate = now.toISOString();
      const fileInput = document.getElementById("documentInput");

      this.timer = 0;
      this.newMessage = "";
      this.mobilePausedRecording = false;

      const bodyObject = {
        message: newMessage ? newMessage : null,
        timestamp: formatedDate,
        id: this.opportunity.id,
      };

      if (fileInput.files.length > 0) {
        this.sendMessageWithFile(fileInput, bodyObject);

        return;
      }

      this.$store
        .dispatch("omnichannel/sendMessage", bodyObject)
        .catch((error) => {
          console.log(error);
        });
    },

    async sendMessageWithFile(fileInput, message) {
      this.isSendingFile = true;

      const files = fileInput.files;
      const filePromises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        const promise = new Promise((resolve) => {
          const reader = new FileReader();
          const mimeType = file.type;
          let base64String;

          reader.onload = (event) => {
            base64String = event.target.result.split(",")[1];

            const fileData = {
              name: file.name,
              base64File: "data:" + mimeType + ";base64," + base64String,
            };

            this.filesArray.unshift(fileData);
            resolve();
          };

          reader.readAsDataURL(file);
        });

        filePromises.push(promise);
      }

      await Promise.all(filePromises);

      message.files = this.filesArray;
      message.dialogId = this.opportunity.remote_id;

      this.$store
        .dispatch("omnichannel/sendMessage", message)
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isSendingFile = false;
          this.clearInputField();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.white-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding-bottom: 10px;
  left: 0;
  bottom: 0px;
  width: calc(100%);
  background: #ffffff;

  .input-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: calc(100% - 10px);
    padding: 7px 10px 7px 10px;
    border: solid 2px #cbced1;
    border-radius: 800px;
    background: #ffffff;

    .reopen-button {
      font-size: 12px;
      padding: 10px 20px;
      border-radius: 20px;
    }

    .responsible-avatar {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .emoji-selector {
      position: absolute;
      left: 0;
      bottom: 50px;
    }

    .audio-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;

      .sending-instruction {
        font-size: 13px;
        padding: 5px 12px;
        border-radius: 20px;
        background: #f2f3f5;
        color: #8e8e8e;
      }

      .timer-recorder {
        display: flex;
        align-items: center;
        width: 70px;
        gap: 7px;
        color: #000000;

        .green-ring {
          height: 20px;
          width: 20px;
          border-radius: 100%;
          border: 4px solid #2ed47a;
        }
      }
    }

    .rounded-enter {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px;
      border-radius: 100%;
      background: #046af3;
      cursor: pointer;
    }

    i {
      font-size: 20px;
      cursor: pointer;
    }

    input {
      width: 90%;
      border: none;
    }

    input:focus {
      outline: none;
    }
  }

  @media (max-width: 800px) {
    position: fixed;
    padding-left: 5px;
    width: calc(100% - 15px);

    .input-box {
      .audio-box {
        width: 85%;
      }
    }
  }
}

.draggable-box {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  bottom: 0px;
  z-index: 100000;
  padding: 1rem;
  opacity: 0;
}
</style>
