<template>
  <div>
    <audio id="remoteAudio" style="display: none" controls autoplay />

    <div
      v-if="showMobileTapPlay"
      @click="stopPropagation()"
      class="mobile-tap-play"
    >
      <div class="half-modal-warning">
        <div class="phone-token">
          <lottie-animation
            class="phone-animation"
            ref="anim"
            :animationData="require('@/assets/images/lottie/phone-lottie.json')"
            :loop="true"
            :autoPlay="true"
            :speed="1"
          />
        </div>

        <div style="font-size: 22px; font-weight: 600; color: #000000">
          Habilitar áudio
        </div>

        <div style="font-size: 16px; color: #686868">
          Permitir a reprodução de som neste dispositivo.
        </div>

        <b-button
          style="
            font-size: 16px;
            font-weight: 600;
            width: 120px;
            border: none;
            background: #046af3 !important;
          "
        >
          PERMITIR
        </b-button>
      </div>
    </div>

    <div
      v-if="external_phone_is_dialing && !showDialBar"
      @click="changeShowDialBar"
      class="minimized-phone"
    >
      <lottie-animation
        class="lottie-animation"
        ref="anim"
        :animationData="require('@/assets/images/lottie/phone-lottie.json')"
        :loop="true"
        :autoPlay="true"
        :speed="1"
      />
    </div>

    <div v-if="external_phone_is_dialing" v-show="showDialBar">
      <div v-if="hasLoadedInfo" class="dial-bar">
        <div
          :style="
            external_phone_status == 'onCall'
              ? 'border: solid 1px #2ed47a80; background: #2ed47a0d;'
              : ''
          "
          class="dial-line"
        >
          <div class="dial-sequence-text">
            {{
              external_phone_status != "onCall"
                ? "Oportunidades: " + currentIndex + "/" + totalCount
                : "Em atendimento"
            }}
          </div>

          <div
            class="d-flex"
            style="justify-content: center; align-items: center"
          >
            <div
              :style="
                external_phone_status == 'onCall'
                  ? 'border: solid 4px #2ed47a;'
                  : ''
              "
              class="white-red-circle"
            />

            <div style="min-width: 30px">
              {{ formatTime(timer) }}
            </div>

            <div>
              {{ " (" + currentAttempt + "° tentativa)" }}
            </div>
          </div>
        </div>

        <div
          v-if="opportunity"
          @click="showOpportunityModal"
          class="dial-opportunity"
        >
          <div class="opportunity-line">
            <div
              class="d-flex top-opportunity-info w-100"
              style="align-items: center"
            >
              <Avatar
                :avatarLetter="$service.getFirstLetter(opportunity.name)"
                :hasBorder="false"
                :hasStatus="false"
                :size="2"
                avatarColor="#FFFFFF"
                avatarBackground="#046AF3"
              />

              <div>
                <div style="font-size: 15px; font-weight: 500; color: white">
                  {{ opportunity.name || "Oportunidade sem responsável" }}
                </div>

                <div style="font-size: 12px; color: #999999">
                  {{ opportunity.contact.phone_number }}
                </div>
              </div>
            </div>

            <div class="d-flex" style="align-items: center; gap: 10px">
              <div class="price-box">
                {{
                  opportunity.price
                    ? $service.formatBrlAmountSymbol(opportunity.price)
                    : $service.formatBrlAmountSymbol(0)
                }}
              </div>

              <b-button
                class="up-button"
                v-if="external_phone_status == 'onCall'"
              >
                <feather-icon icon="ChevronUpIcon" size="18" />
              </b-button>
            </div>
          </div>

          <div class="opportunity-line quick-opportunity-info">
            <div class="d-flex" style="align-items: center; margin-right: 7px">
              <i
                style="font-size: 13px; margin-right: 5px"
                class="icon-arrow-circle-black"
              />

              {{
                opportunity.product ? opportunity.product.name : "Sem produto"
              }}
            </div>

            <div class="d-flex" style="align-items: center">
              <i
                style="font-size: 13px; margin-right: 5px"
                class="icon-client"
              />

              {{
                opportunity.user
                  ? opportunity.user.name
                  : "Oportunidade sem Responsável"
              }}
            </div>
          </div>
        </div>

        <b-button
          v-if="external_phone_status != 'onCall'"
          @click="pauseDialing"
          :class="external_phone_is_paused ? 'dial-paused' : 'dial-on-going'"
          id="pause-resume-button"
          class="dial-pause"
        >
          <feather-icon
            :icon="external_phone_is_paused ? 'PlayIcon' : 'PauseIcon'"
            :style="external_phone_is_paused ? 'margin-left: 3px' : ''"
            size="18"
          />
        </b-button>

        <b-tooltip
          target="pause-resume-button"
          triggers="hover"
          placement="top"
        >
          {{ external_phone_is_paused ? "Retomar" : "Pausar" }}
        </b-tooltip>

        <b-button
          v-if="external_phone_status != 'onCall'"
          @click="finishDialing"
          class="dial-stop"
          variant="danger"
        >
          Finalizar discagem
        </b-button>

        <b-button
          v-if="external_phone_status == 'onCall'"
          @click="terminateCall"
          id="finish-call-button"
          class="dial-stop"
          style="border-radius: 10px"
          variant="danger"
        >
          <feather-icon icon="PhoneOffIcon" size="18" />

          <b-tooltip
            target="finish-call-button"
            triggers="hover"
            placement="top"
          >
            Finalizar ligação
          </b-tooltip>
        </b-button>

        <div style="position: relative">
          <b-button @click="changeShowDialBar" class="show-dialbar-button">
            <feather-icon icon="ChevronDownIcon" size="18" />
          </b-button>
        </div>
      </div>

      <div v-else class="dial-bar">
        <b-spinner />
      </div>

      <div
        v-if="external_phone_status != 'onCall'"
        class="mobile-buttons-bar-on-wait"
      >
        <b-button
          @click="pauseDialing"
          :class="external_phone_is_paused ? 'dial-paused' : 'dial-on-going'"
          id="pause-resume-button"
          class="dial-pause-mobile"
        >
          <feather-icon
            :icon="external_phone_is_paused ? 'PlayIcon' : 'PauseIcon'"
            :style="external_phone_is_paused ? 'margin-left: 3px' : ''"
            size="18"
          />
        </b-button>

        <b-button
          v-if="external_phone_status != 'onCall'"
          @click="finishDialing"
          class="dial-stop-mobile"
          variant="danger"
        >
          Finalizar
        </b-button>
      </div>

      <div
        v-if="external_phone_status == 'onCall'"
        class="mobile-buttons-bar-on-call"
      >
        <b-button
          @click="terminateCall"
          id="finish-call-button"
          class="dial-stop-mobile"
          style="border-radius: 10px"
          variant="danger"
        >
          <div>
            <feather-icon icon="PhoneOffIcon" size="18" />
          </div>
        </b-button>
      </div>

      <b-modal
        id="bv-dial-opportunity"
        class="modal-dialog"
        hide-footer
        hide-header
      >
        <div class="d-block text-center">
          <opportunity-and-contacts
            :opportunity="opportunity"
            :isFromExternalCall="true"
            @saved="reopenOpportunity"
            @refresh="reopenOpportunity"
            @closed="hideModal('bv-dial-opportunity')"
          />
        </div>
      </b-modal>

      <b-modal
        id="bv-dial-opportunity-from-closure"
        class="modal-dialog"
        hide-footer
        hide-header
        no-close-on-backdrop
      >
        <div class="d-block text-center">
          <opportunity-and-contacts
            :opportunity="opportunity"
            :isFromExternalCall="true"
            @saved="continueAfterOportunityEdit()"
            @refresh="continueAfterOportunityEdit()"
            @closed="
              () => {
                hideModal('bv-dial-opportunity-from-closure');
                continueAfterOportunityEdit();
              }
            "
          />
        </div>
      </b-modal>

      <b-modal
        v-if="hasLoadedInfo"
        id="bv-task_external_phone"
        class="modal-dialog"
        hide-footer
        hide-header
        no-close-on-backdrop
      >
        <task-form
          :isUpdate="false"
          :opportunity="opportunity"
          :parentId="opportunity.id"
          :isRegistryProp="true"
          :fromTaskCall="wasCallAnswered"
          :fromTaskCallAttempt="!wasCallAnswered"
          :fromExternalPhone="true"
          @saved="continueAfterTaskRegister"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import Avatar from "@/views/components/Avatar.vue";
import { BButton, BSpinner, BTooltip } from "bootstrap-vue";
import CircularJSON from "circular-json";
import JsSIP from "jssip";
import LottieAnimation from "lottie-web-vue";
import OpportunityAndContacts from "@/views/forms/OpportunityAndContacts.vue";
import TaskForm from "@/views/forms/TaskForm.vue";

export default {
  components: {
    Avatar,
    BButton,
    BSpinner,
    BTooltip,
    LottieAnimation,
    OpportunityAndContacts,
    TaskForm,
  },

  data() {
    return {
      currentAttempt: null,
      currentIndex: null,
      hasLoadedInfo: false,
      localRowId: null,
      newPhone: null,
      opportunity: null,
      session: null,
      showDialBar: true,
      showMobileTapPlay: false,
      timer: 0,
      timerInterval: null,
      totalCount: null,
      wasCallAnswered: false,
    };
  },

  props: {},

  computed: {
    rowId() {
      return this.$store.state.externalPhone.rowId;
    },

    external_phone_status() {
      return this.$store.getters["externalPhone/getPhoneStatus"];
    },

    external_phone_credentials() {
      return this.$store.getters["externalPhone/getUserCredentials"];
    },

    external_phone_stop_tick() {
      return this.$store.getters["externalPhone/getStopCallTick"];
    },

    external_phone_start_tick() {
      return this.$store.getters["externalPhone/getStartCallTick"];
    },

    external_phone_is_paused() {
      return this.$store.getters["externalPhone/getIsPaused"];
    },

    external_phone_is_dialing() {
      return this.$store.getters["externalPhone/getIsDialing"];
    },
  },

  beforeDestroy() {
    clearInterval(this.timerInterval);

    this.disconnectPhone();
  },

  watch: {
    external_phone_status(oldValue, newValue) {
      if (oldValue != newValue) {
        if (this.external_phone_status == "onCall") {
          this.restartTimer();
        }
      }
    },

    showDialBar() {
      this.changeStoreShowBar();
    },

    external_phone_start_tick() {
      this.getSIPConection();
    },

    external_phone_stop_tick() {
      this.terminateCall();
    },
  },

  methods: {
    showModal(modal) {
      this.$bvModal.show(modal);
    },

    hideModal(modal) {
      this.$bvModal.hide(modal);
    },

    emitEvent(event) {
      this.$emit(event);
    },

    changeStoreStatus(status) {
      this.$store.commit("externalPhone/CHANGE_STATUS", status);
    },

    changeStoreIsDialing(status) {
      this.$store.commit("externalPhone/CHANGE_IS_DIALING", status);
    },

    changeStoreIsPaused(status) {
      this.$store.commit("externalPhone/CHANGE_IS_PAUSED", status);
    },

    changeShowMobileTapPlay(show) {
      this.showMobileTapPlay = show;
    },

    changeStoreShowBar() {
      this.$store.commit("externalPhone/CHANGE_SHOW_BAR", this.showDialBar);
    },

    changeShowDialBar() {
      this.showDialBar = !this.showDialBar;
    },

    reopenOpportunity() {
      this.hideModal("bv-dial-opportunity");
      this.showModal("bv-dial-opportunity");
    },

    finishDialing() {
      this.endRowOnBackend();
      this.changeStoreIsDialing(false);
      this.emitEvent("endPhoneSession");

      window.location.reload();
    },

    showOpportunityModal() {
      if (this.external_phone_status == "onCall") {
        this.showModal("bv-dial-opportunity");
      }
    },

    stopPropagation() {
      this.showMobileTapPlay = false;
    },

    pauseDialing() {
      this.changeStoreIsPaused(!this.external_phone_is_paused);

      if (this.external_phone_is_paused) {
        this.timer = 0;

        this.changeStoreStatus("paused");

        this.pauseRowOnBackend();
      } else {
        this.changeStoreStatus("onHold");

        this.startCalls();
      }
    },

    updateTimer() {
      if (!this.external_phone_is_paused) {
        this.timer++;
      }

      if (
        this.external_phone_status != "onCall" &&
        this.external_phone_status != "paused" &&
        this.timer > 30
      ) {
        this.startCalls();
      }
    },

    startTimer() {
      this.timerInterval = setInterval(this.updateTimer, 1000);
    },

    restartTimer() {
      this.timer = 0;
    },

    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);

      const remainingSeconds = seconds % 60;

      return `${minutes}:${
        remainingSeconds < 10 ? "0" : ""
      }${remainingSeconds}`;
    },

    continueAfterTaskRegister() {
      if (!this.newPhone.isConnected()) {
        this.$bvToast.toast("Conexão com a telefonia perdida!.", {
          title: `Perda de conexão!`,
          autoHideDelay: 1500,
          variant: "danger",
          toaster: "b-toaster-top-right",
          solid: true,
        });

        setTimeout(() => {
          this.finishDialing();
        }, 1500);

        return;
      }

      this.hideModal("bv-task_external_phone");
      this.hideModal("bv-dial-opportunity");

      this.showModal("bv-dial-opportunity-from-closure");
    },

    continueAfterOportunityEdit() {
      if (!this.newPhone.isConnected()) {
        this.$bvToast.toast("Conexão com a telefonia perdida!.", {
          title: `Perda de conexão!`,
          autoHideDelay: 1500,
          variant: "danger",
          toaster: "b-toaster-top-right",
          solid: true,
        });

        setTimeout(() => {
          this.finishDialing();
        }, 1500);

        return;
      }

      this.changeStoreIsPaused(false);

      this.hideModal("bv-dial-opportunity-from-closure");
      this.hideModal("bv-task_external_phone");
      this.hideModal("bv-dial-opportunity");

      this.changeStoreStatus("onHold");

      this.startCalls();
    },

    pauseRowOnBackend() {
      this.$store
        .dispatch("externalPhone/rowPauseEvent", this.rowId)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },

    endRowOnBackend() {
      this.$store
        .dispatch("externalPhone/rowStopEvent", this.rowId)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },

    disconnectPhone() {
      if (this.newPhone) {
        this.newPhone.unregister();
      }
    },

    terminateCall() {
      if (this.session) {
        try {
          this.session.terminate();
        } catch (error) {
          const remoteAudio = document.getElementById("remoteAudio");
          remoteAudio.srcObject = null;

          if (this.timer > 15) {
            this.wasCallAnswered = true;
          } else {
            this.wasCallAnswered = false;
          }

          this.showModal("bv-task_external_phone");

          this.changeStoreIsPaused(true);

          this.changeStoreStatus("paused");

          throw new Error(
            "Estado da conexão e chamada no momento do erro" +
              CircularJSON.stringify(this.session) +
              CircularJSON.stringify(this.newPhone)
          );
        }
      }
    },

    startCalls() {
      if (this.rowId && this.external_phone_is_dialing) {
        this.localRowId = this.rowId;
        this.hasLoadedInfo = false;

        this.changeStoreStatus("onHold");

        this.$store
          .dispatch("externalPhone/rowStartEvent", this.rowId)
          .then((response) => {
            const currentCallAttempt = response.data;

            if (currentCallAttempt.status == "Finished") {
              this.emitEvent("endPhoneSession");
              this.changeStoreIsDialing(false);

              window.location.reload();
            } else {
              this.totalCount = currentCallAttempt.total_count;
              this.currentIndex = currentCallAttempt.current_index;
              this.currentAttempt =
                currentCallAttempt.opportunity_call.attempts;
              this.opportunity =
                currentCallAttempt.opportunity_call.opportunity;

              this.hasLoadedInfo = true;

              if (!this.timerInterval) {
                this.startTimer();
              } else {
                this.restartTimer();
              }
            }
          })
          .catch((error) => {
            this.$bvToast.toast(
              "Não conseguimos iniciar sua fila de ligações, tente mais tarde ou contate o suporte.",
              {
                title: `Algo deu errado!`,
                autoHideDelay: 1000,
                variant: "danger",
                toaster: "b-toaster-top-right",
                solid: true,
              }
            );

            setTimeout(() => {
              console.log(error);
              this.finishDialing();
            }, 1000);
          });
      }
    },

    getSIPConection() {
      let socket = new JsSIP.WebSocketInterface(
        "wss://institutomix.native-infinity.com.br/ws"
      );

      let configuration = {
        sockets: [socket],
        uri:
          "sip:" +
          this.external_phone_credentials.user_name +
          "@institutomix.native-infinity.com.br",
        password: this.external_phone_credentials.password,
      };

      this.newPhone = new JsSIP.UA(configuration);

      this.newPhone.on("dispatch", () => this.changeStoreStatus("Despachando"));

      this.newPhone.on("connecting", () =>
        this.changeStoreStatus("Conectando")
      );

      this.newPhone.on("disconnected", () =>
        this.changeStoreStatus("Desconectado")
      );

      this.newPhone.on("unregistered", () =>
        this.changeStoreStatus("Sem registro")
      );

      this.newPhone.on("registrationFailed", (event) => {
        this.changeStoreStatus("Falha de registro");
      });

      this.newPhone.on("newRTCSession", (event) => {
        this.handleNewSession(event);
      });

      this.newPhone.start();

      this.changeStoreShowBar();
      this.startCalls();
    },

    handleNewSession(event) {
      if (this.external_phone_status !== "onHold") {
        return;
      }

      this.changeStoreStatus("onCall");

      this.showDialBar = true;

      const newSession = event.session;

      newSession.on("ended", () => {
        const remoteAudio = document.getElementById("remoteAudio");
        remoteAudio.srcObject = null;

        if (this.timer > 15) {
          this.wasCallAnswered = true;
        } else {
          this.wasCallAnswered = false;
        }

        this.showModal("bv-task_external_phone");

        this.changeStoreIsPaused(true);

        this.changeStoreStatus("paused");
      });

      newSession.on("accepted", () => {
        this.changeStoreStatus("onCall");
      });

      this.session = newSession;

      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((localStream) => {
          newSession.answer({
            mediaStream: localStream,
            mediaConstraints: {
              audio: true,
              video: false,
            },
          });

          this.showModal("bv-dial-opportunity");

          newSession.connection.addEventListener("addstream", (event) => {
            if (window.innerWidth > 800) {
              const remoteAudio = document.getElementById("remoteAudio");
              remoteAudio.srcObject = event.stream;

              remoteAudio.play();
            } else {
              this.changeShowMobileTapPlay(true);

              const playRemoteAudio = () => {
                const remoteAudio = document.getElementById("remoteAudio");

                remoteAudio.srcObject =
                  newSession.connection.getRemoteStreams()[0];

                remoteAudio.play();
              };

              document.addEventListener("touchend", playRemoteAudio, {
                once: true,
              });
            }
          });
        })
        .catch((error) => {
          console.error("Erro ao acessar o dispositivo de áudio:", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-tap-play {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 10000000;
  background: #0000006b;

  .half-modal-warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    height: 255px;
    width: 100%;
    gap: 15px;
    border-radius: 20px 20px 0px 0px;
    background: #ffffff;

    .phone-token {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      font-size: 30px;
      height: 94px;
      width: 94px;
      top: -47px;
      left: calc(50% - 47px);
      border-radius: 100%;
      background: #ffff;

      color: #ffffff;

      .phone-animation {
        min-height: 90px;
        min-width: 90px;
        max-height: 90px;
        max-width: 90px;
      }
    }
  }
}

.dial-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 90px;
  width: 100vw;
  z-index: 1000;
  bottom: 0;
  padding: 10px 0;
  gap: 15px;
  background: #2f343d;

  .dial-line,
  .dial-opportunity {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 5px 10px;
    border-radius: 8px;
    background: #0000004d;
  }

  .dial-line {
    justify-content: center;
    width: 185px;

    .dial-sequence-text {
      text-align: center;
      font-size: 15px;
      line-height: 23px;
      letter-spacing: 0em;
      font-weight: 500;
      color: #ffffff;
    }

    .white-red-circle {
      height: 20px;
      width: 20px;
      margin-right: 5px;
      border-radius: 100%;
      border: solid 4px #ff0000;
      background: #ffffff;
    }
  }

  .dial-opportunity {
    .opportunity-line {
      display: flex;
      align-items: flex-start;
      gap: 12px;

      .top-opportunity-info {
        line-height: 18px;
        letter-spacing: 0.01em;
        text-align: left;
      }

      .price-box {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        padding: 5px 7px;
        border-radius: 6px;
        border: solid 1px #046af3;
        color: #046af3;
      }

      .up-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 7px;
        border-radius: 6px;
        border: none;
        background: #2f343d !important;
        color: #ffffff;
      }

      .quick-opportunity-info {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.01em;
        text-align: left;
        color: #4c5862;
      }
    }
  }

  .dial-pause {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    width: 42px;
    padding: 0;
    margin-left: 10px;
    border-radius: 100%;
    border: none;
    color: #ffffff;
  }

  .dial-paused {
    background: #035acf !important;
  }

  .dial-on-going {
    background: #0000004d !important;
  }

  .dial-stop {
    font-size: 18px;
    font-weight: 600;
    height: 50px;
    border-radius: 73px;
    border: none;
    background: #ff0025 !important;
    color: #ffffff;
  }

  .show-dialbar-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 25px;
    width: 25px;
    top: -55px;
    padding: 0;
    border: none;
    color: #ffffff;
    background: #2f343d !important;
  }
}

.mobile-buttons-bar-on-wait,
.mobile-buttons-bar-on-call {
  display: none;
}

.minimized-phone {
  display: flex;
  position: fixed;
  height: 55px;
  width: 55px;
  right: 75px;
  bottom: 5px;
  z-index: 1000;
  cursor: pointer;

  .lottie-animation {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 800px) {
  .dial-bar {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 185px;
    bottom: 50px;
    padding: 0px 10px 25px 10px;
    border-radius: 20px 20px 0 0;

    .dial-line {
      width: 200px;
      border-radius: 0px 0px 20px 20px;

      .white-red-circle {
        height: 20px;
        width: 20px;
        margin-right: 5px;
        border-radius: 100%;
        border: solid 4px #ff0000;
        background: #ffffff;
      }
    }

    .dial-pause {
      display: none;
    }

    .dial-stop {
      display: none;
    }
  }

  .mobile-buttons-bar-on-call,
  .mobile-buttons-bar-on-wait {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1000;
    padding: 5px;
    background: white;
  }

  .mobile-buttons-bar-on-wait {
    gap: 15px;

    .dial-pause-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 42px;
      width: 42px;
      padding: 0;
      border-radius: 100%;
      border: none;
      color: #ffffff;
    }

    .dial-stop-mobile {
      font-size: 18px;
      font-weight: 600;
      height: 42px;
      border-radius: 73px;
      border: none;
      background: #ff0025 !important;
      color: #ffffff;
    }

    .dial-paused {
      background: #035acf !important;
    }

    .dial-on-going {
      background: #2f343d !important;
    }
  }

  .mobile-buttons-bar-on-call {
    align-items: flex-end;
    height: 50px;
    overflow: visible;

    .dial-stop-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      height: 55px;
      width: 55px;
      border-radius: 100% !important;
      border: none;
      box-shadow: 0 0 0 7px #ffffff;
      background: #ff0025 !important;
      color: #ffffff;
    }
  }

  .show-dialbar-button {
    top: -170px !important;
    left: 125px;
  }

  .minimized-phone {
    right: 5px;
    bottom: 120px;
  }
}
</style>
