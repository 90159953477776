const initialState = () => ({
  isOpen: false,

  updateTick: false,

  warnings: [
    {
      id: "newDefaultDate",
      created_at: "01/08/2023 - 09:00",
      expiration: "08/08/2023",
      message:
        "Agora, o nosso CRM exibe somente as oportunidades dos últimos dias no Pipeline (área inicial da plataforma). Se você deseja ver todas as oportunidades, basta filtrar a data desejada.",
    },
  ],
});

const getters = {
  getAllWarnings(state) {
    return state.warnings;
  },
};

const mutations = {
  CHANGE_NEWS_MODAL_CONDITION(state, condition) {
    state.isOpen = condition;
  },

  TICK_UPDATE(state) {
    state.updateTick = !state.updateTick;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
};
