/* eslint-disable no-empty-pattern */
import Vue from "vue";
import { instance } from "@/config/index.js";

const initialState = () => ({
  source_types: [
    { id: "Ligacao", title: "Ligação" },
    { id: "Bella", title: "Bella" },
    { id: "WhatsappEscola", title: "Whatsapp Escola" },
    { id: "FormularioSite", title: "Formulário Site" },
    { id: "FormularioRedesSociais", title: "Formulário Redes Sociais" },
    { id: "VisitaEscola", title: "Visita Escola" },
    { id: "Messenger", title: "Messenger" },
    { id: "Inbox", title: "Inbox" },
    { id: "Email", title: "Email" },
    { id: "Direct", title: "Direct" },
    { id: "Comentario", title: "Comentário" },
    { id: "Importacao", title: "Importação" },
    { id: "Offline", title: "Offline" },
    { id: "URA", title: "URA" },
  ],

  seller_approach: [
    { id: "DigitalService", title: "Atendimento Digital" },
    { id: "RemoteSales", title: "Televendas" },
    { id: "SpontaneousVisit", title: "Visita Espontanea" },
    { id: "ExternalVisit", title: "Visita PAP" },
    { id: "StreetVisit", title: "Visita Street" },
    { id: "ScheduledVisit", title: "Agendamento de visita" },
  ],

  reasons_lost: [
    { title: "Decidiu não comprar agora", id: "NotReady" },
    { title: "Fechou com a concorrência", id: "LostToConcurrence" },
    {
      title: "Produto não atende a necessidade",
      id: "NotMeetRequirements",
    },
    { title: "Demora no atendimento", id: "NoResponse" },
    { title: "Não fechou devido ao preço", id: "HighPrice" },
    { title: "Oportunidade duplicada", id: "Duplicated" },
    { title: "Falar com o cônjuge", id: "TalkToSpouse" },
    { title: "Parou de responder", id: "LostContact" },
    { title: "Agendou e não compareceu", id: "MissedSchoolVisit" },
    { title: "Apenas conferindo valores", id: "CheckingPrices" },
    {
      title: "Falta de disponibilidade",
      id: "NoMatchingClassScheduleAvailable",
    },
    { title: "Telefone inexistente", id: "PhoneNumberDoesNotExist" },
    { title: "A escola fica muito distante do aluno", id: "SchoolTooFar" },
    { title: "Cônjuge não deixou", id: "SpouseDidNotAuthorize" },
    { title: "Cliente não atende", id: "Contactless" },
    { title: "Outros", id: "other" },
    { title: "Testes", id: "Testing" },
  ],

  lost_reasons_HR: [
    {
      id: "NotMeetJobRequirements",
      title: "Não atendeu aos requisitos",
    },
    {
      id: "LackedMotivation",
      title: "Falta de motivação",
    },
    {
      id: "LacksReferences",
      title: "Sem referências",
    },
    {
      id: "NotStandOut",
      title: "Não se destacou",
    },
    {
      id: "HasPoorPastPerformance",
      title: "Histórico insatisfatório",
    },
    {
      id: "LacksAvailability",
      title: "Indisponibilidade de horário",
    },
    {
      id: "HasCriminalHistory",
      title: "Histórico inadequado",
    },
    {
      id: "DidNotAttendInterview",
      title: "Faltou à entrevista",
    },
    {
      id: "AcceptedAnotherJobOffer",
      title: "Aceitou outra oferta",
    },
    {
      id: "WasQualifiedButNotSelected",
      title: "Não selecionado",
    },
    {
      id: "NoSpecificVacancy",
      title: "Sem vaga específica",
    },
    {
      id: "NoVacanciesAvailable",
      title: "Todas as vagas preenchidas",
    },
  ],

  states: [
    { title: "Acre", id: "AC" },
    { title: "Alagoas", id: "AL" },
    { title: "Amapá", id: "AP" },
    { title: "Amazonas", id: "AM" },
    { title: "Bahia", id: "BA" },
    { title: "Ceará", id: "CE" },
    { title: "Distrito Federal", id: "DF" },
    { title: "Espírito Santo", id: "ES" },
    { title: "Goiás", id: "GO" },
    { title: "Maranhão", id: "MA" },
    { title: "Mato Grosso", id: "MT" },
    { title: "Mato Grosso do Sul", id: "MS" },
    { title: "Minas Gerais", id: "MG" },
    { title: "Pará", id: "PA" },
    { title: "Paraíba", id: "PB" },
    { title: "Paraná", id: "PR" },
    { title: "Pernambuco", id: "PE" },
    { title: "Piauí", id: "PI" },
    { title: "Rio de Janeiro", id: "RJ" },
    { title: "Rio Grande do Norte", id: "RN" },
    { title: "Rio Grande do Sul", id: "RS" },
    { title: "Rondônia", id: "RO" },
    { title: "Roraima", id: "RR" },
    { title: "Santa Catarina", id: "SC" },
    { title: "São Paulo", id: "SP" },
    { title: "Sergipe", id: "SE" },
    { title: "Tocantins", id: "TO" },
  ],

  campaigns: [],

  medias: [],
});

const getters = {
  getSourceTypes(state) {
    return state.source_types;
  },

  getSellerApprouch(state) {
    return state.seller_approach;
  },

  getLostReasons(state) {
    const url = window.location.host;

    if (url == "rh.institutomix.com.br") {
      return state.reasons_lost.concat(state.lost_reasons_HR);
    } else {
      return state.reasons_lost;
    }
  },

  getLostReasonsForHR(state) {
    return state.lost_reasons_HR;
  },

  getCampaigns(state) {
    return state.campaigns;
  },

  getMedias(state) {
    return state.medias;
  },

  getSourceStates(state) {
    return state.states;
  },

  resetState(state) {
    const inital = initialState();

    Object.keys(inital).forEach((key) => {
      state[key] = inital[key];
    });
  },

  async getStages() {
    const workspaceId = localStorage.getItem("currentWorkspace");


    let salesStage = await new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/sales-stages`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err.response));
    });

    let stages = [];

    salesStage.forEach((saleStage, index) => {
      stages.push({
        id: saleStage.id,
        title: saleStage.name,
        color: saleStage.color,
        type: saleStage.type,
        value: 0,
        totalCount: 0,
        totalAmount: 0,
        step: {
          name: `Etapa ${index + 1}`,
          count: index + 1,
        },
        hasRequest: true,
        tasks: [],
      });
    });

    return stages;
  },
};

const mutations = {
  SET_LOGOUT(state) {
    const inital = initialState();

    Object.keys(inital).forEach((key) => {
      state[key] = inital[key];
    });
  },
};

const actions = {
  save({}, opportunity) {
    let workspaceId = localStorage.getItem("currentWorkspace");

    if (opportunity.workspace_id) {
      workspaceId = opportunity.workspace_id;
    }

    // const opportunityId = 1
    const postUrl = `/workspaces/${workspaceId}/opportunities`;

    if (opportunity.id) {
      // Update
      return new Promise((resolve, reject) => {
        instance()
          .put(`${postUrl}/${opportunity.id}`, opportunity)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    }

    // Insert
    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, opportunity)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  update({}, opportunity) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    const postUrl = `/workspaces/${workspaceId}/opportunities/${opportunity.id}`;

    return new Promise((resolve, reject) => {
      instance()
        .put(postUrl, opportunity)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  updateStep({}, opportunity) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    const localOpportunity = opportunity;
    localOpportunity.display_order = "top";

    // const opportunityId = 1
    const url = `/workspaces/${workspaceId}/opportunities/${localOpportunity.id}/move-to`;

    return new Promise((resolve, reject) => {
      instance()
        .put(url, localOpportunity)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  assign({}, opportunity) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    const localOpportunity = opportunity;
    localOpportunity.display_order = "top";

    const data = {
      user_id: localOpportunity.user_id,
    };

    // const opportunityId = 1
    const url = `/workspaces/${workspaceId}/opportunities/${localOpportunity.id}/assign`;

    return new Promise((resolve, reject) => {
      instance()
        .put(url, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetch({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/opportunities${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  fetchCampaigns({ state }) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/campaigns?limit=500`)
        .then((response) => {
          state.campaigns = response.data;
          resolve(response.data);
        })
        .catch((err) => reject(err.response));
    });
  },

  fetchMedias({ state }, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/medias${params || "?limit=500"}`)
        .then((response) => {
          state.medias = response.data;
          resolve(response.data);
        })
        .catch((err) => reject(err.response));
    });
  },

  fetchHistory({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/opportunities/history${params || ""}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  transferOpportunity({}, data) {
    const workspaceId = localStorage.getItem("currentWorkspace");

    const putUrl = `/workspaces/${workspaceId}/opportunities/${data.opportunity_id}/transfer`;

    const dataForTransfer = {
      workspace_id: data.workspace_id,
      user_id: data.user_id,
    };

    return new Promise((resolve, reject) => {
      instance()
        .put(putUrl, dataForTransfer)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  massTransferOpportunity({}, data) {
    const postUrl = `/workspaces/${data.workspace_id}/opportunities/batch-transfer`;
    let dataForTransfer;

    if (data.user_id) {
      dataForTransfer = {
        opportunity_distribution_user_id: data.user_id,
        opportunity_distribution_algorithm: data.distribution_algorithm,
        opportunities: data.opportunities,
      };
    } else {
      dataForTransfer = {
        opportunity_distribution_algorithm: data.distribution_algorithm,
        opportunities: data.opportunities,
      };
    }

    return new Promise((resolve, reject) => {
      instance()
        .post(postUrl, dataForTransfer)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  fetchOne({}, id) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    return new Promise((resolve, reject) => {
      instance()
        .get(`/workspaces/${workspaceId}/opportunities/${id}`)
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  getUsers({}, fetchProprieties) {
    return new Promise((resolve, reject) => {
      instance()
        .get(
          `/workspaces/${fetchProprieties.workspaceId}/users${
            fetchProprieties.params || ""
          }`
        )
        .then((response) => resolve(response))
        .catch((err) => reject(err.response));
    });
  },

  close({}, opportunity) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    const postUrl = `/workspaces/${workspaceId}/opportunities/${opportunity.id}/close`;

    return new Promise((resolve, reject) => {
      instance()
        .put(postUrl, opportunity)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  //Products
  fetchProducts({}, params) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    const postUrl = `/workspaces/${workspaceId}/products${params || ""}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(postUrl)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },

  fetchProduct({}, id) {
    const workspaceId = localStorage.getItem("currentWorkspace");


    const url = `/workspaces/${workspaceId}/products/${id}`;

    return new Promise((resolve, reject) => {
      instance()
        .get(url)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  },
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  actions,
};
